<template>
  <div class="desking-area">
    <!-- desking-box -->
    <div class="desking-box">
      <div class="customer-info">
        <div class="user-img">
          <div class="button-wrapper">
            <span class="label">
              <img
                class="img-fluid"
                src="../../assets/img/profile.svg"
                alt=""
              /> </span
            ><input
              type="file"
              name="upload"
              id="upload"
              class="upload-box"
              placeholder="Upload File"
            />
          </div>
        </div>
        <div class="cus-name customerdetails">
          <div class="detais-title">
            <h3>
              Customer Information
              <a class="add-cus" @click="updateCustomer"
                ><img
                  class="img-fluid"
                  src="../../assets/img/edit-button.svg"
                  alt=""
                />
                <span style="margin-left: 5px">Update</span></a
              >
            </h3>
          </div>
          <table class="table">
            <tbody>
              <tr>
                <td>Name:</td>

                <td>
                  <input
                    type="text"
                    class="form-control"
                    v-model="customerFullname"
                  />
                </td>
              </tr>

              <tr>
                <td>Phone:</td>

                <td>
                  <input
                    type="text"
                    class="form-control"
                    v-model="LDetail.c_phone"
                  />
                </td>
              </tr>

              <tr>
                <td>Email:</td>

                <td>
                  <input
                    type="text"
                    class="form-control"
                    v-model="LDetail.c_email"
                  />
                </td>
              </tr>

              <tr>
                <td>Street Address:</td>

                <td>
                  <input
                    type="text"
                    class="form-control"
                    v-model="LDetail.c_street"
                  />
                </td>
              </tr>

              <tr>
                <td>Unit/Apt #:</td>

                <td>
                  <input type="text" class="form-control" placeholder="E7" />
                </td>
              </tr>

              <tr>
                <td>City:</td>

                <td>
                  <input
                    type="text"
                    class="form-control"
                    v-model="LDetail.c_city"
                  />
                </td>
              </tr>

              <tr>
                <td>State:</td>

                <td>
                  <!-- <input
                    type="text"
                    class="form-control"
                    v-model="LDetail.c_state"
                  /> -->
                  <select class="form-control" v-model="LDetail.c_state">
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="DC">District Of Columbia</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                  </select>
                </td>
              </tr>

              <tr>
                <td>Zip Code:</td>

                <td>
                  <input
                    type="text"
                    class="form-control"
                    v-model="LDetail.c_zip"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="profile-accordion">
          <!-- accordion -->
          <div class="accordion" id="accordionExample43">
            <div class="card">
              <div class="card-header" id="headingOne43">
                <h2 class="mb-0">
                  <button
                    class="btn btn-link"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseOne43"
                    aria-expanded="true"
                    aria-controls="collapseOne43"
                  >
                    <div class="detais-title">
                      <h3>
                        <img
                          class="img-fluid arrow-down"
                          src="../../assets/img/arrow-down.svg"
                          alt=""
                        />Vehicle Information
                      </h3>
                    </div>
                  </button>
                </h2>
              </div>

              <div
                id="collapseOne43"
                class="collapse show"
                aria-labelledby="headingOne43"
                data-parent="#accordionExample43"
              >
                <div class="card-body">
                  <!-- Vehicle of Interest -->
                  <div class="vehicle-interest">
                    <!-- Vehicle-tab -->
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                      <li class="nav-item">
                        <a
                          class="nav-link active"
                          id="Vehicle-tab"
                          data-toggle="tab"
                          href="#Vehicle"
                          role="tab"
                          aria-controls="Vehicle"
                          aria-selected="true"
                          >Vehicle of Interest</a
                        >
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          id="Trades-tab"
                          data-toggle="tab"
                          href="#Trades"
                          role="tab"
                          aria-controls="Trades"
                          aria-selected="false"
                          @click="this.getTrades"
                          >Trades</a
                        >
                      </li>
                    </ul>
                    <div class="tab-content" id="myTabContent">
                      <div
                        class="tab-pane fade show active"
                        id="Vehicle"
                        role="tabpanel"
                        aria-labelledby="Vehicle-tab"
                      >
                        <!-- detais-title -->
                        <div class="detais-search">
                          <div class="form-group Vehicle">
                            <template v-if="IsV2 && IsV2TokenExist">
                              <autocomplete
                                ref="autocomplete"
                                method="post"
                                :request-headers="{
                                  'Authorization': 'Bearer ' +  $storage.get('auth').user.dealer_autolink_v2_auth_token
                                }"
                                placeholder="Search Inventory"
                                input-class="form-control border-0"
                                :source="
                                  'https://api.dealersgear.com/api/crmInventory?limit=20&offset=0&keyword='
                                "
                                results-property="inventory"
                                :results-display="formattedAutocompleteDisplay"
                                @selected="selectedVehicle"
                              >
                              </autocomplete>  
                            </template>
                            <template v-else>
                              <autocomplete
                                ref="autocomplete"
                                placeholder="Search Inventory"
                                input-class="form-control border-0"
                                :source="
                                  'https://autolinkme.com/webservices/crm/inventory?id=' +
                                  $storage.get('auth').user.dealer_autolink_id +
                                  '&limit=20&offset=0&status=active&search_by=value&search_string='
                                "
                                results-property="data"
                                :results-display="formattedAutocompleteDisplay"
                                @selected="selectedVehicle"
                              >
                              </autocomplete>
                            </template>
                            <img
                              class="img-fluid Vehi-img"
                              src="../../assets/img/search.svg"
                              alt=""
                            />
                          </div>
                        </div>

                        <div class="vehicle-img">
                          <img
                            v-if="voi.v_image"
                            class="img-fluid"
                            v-on:click.stop.prevent="vehicleDetail(voi)"
                            v-bind:src="voi.v_image"
                            alt=""
                          />

                          <img
                            v-else
                            class="img-fluid"
                            v-on:click.stop.prevent="vehicleDetail(voi)"
                            src="../../assets/img/carplaceholder.png"
                          />
                        </div>
                        <div class="vehicle-table">
                          <h4>
                            {{ voi.v_year }} {{ voi.v_make }}
                            {{ voi.v_model }}
                          </h4>
                          <table class="table">
                            <tbody>
                              <tr>
                                <td>Color</td>

                                <td>{{ voi.v_ext_color }}</td>
                              </tr>
                              <tr>
                                <td>Stock#</td>

                                <td>{{ voi.v_stock_no }}</td>
                              </tr>

                              <tr>
                                <td>Miles</td>

                                <td>{{ voi.v_mileage | number("0,0") }}</td>
                              </tr>
                              <tr>
                                <td>VIN</td>
                                <td>{{ voi.v_vin }}</td>
                              </tr>
                              <tr>
                                <td>MSRP</td>
                                <td>
                                  <span v-if="voi.v_msrp"
                                    >${{ voi.v_msrp | number("0,0") }}</span
                                  >
                                  <span v-else>N/A</span>
                                </td>
                              </tr>
                              <tr>
                                <td>Selling Price</td>
                                <td>${{ voi.v_price | formatPrice }}</td>
                              </tr>
                              <tr>
                                <td>Special Price</td>
                                <td>
                                  ${{ voi.v_special_price | formatPrice }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <!-- detais-title -->
                      </div>
                      <div
                        class="tab-pane fade"
                        id="Trades"
                        role="tabpanel"
                        aria-labelledby="Trades-tab"
                      >
                        <!-- detais-title -->
                        <div class="detais-search">
                          <a
                            href="#"
                            class="trade-btn"
                            data-toggle="modal"
                            data-target="#addTradeModal"
                            ><img
                              class="img-fluid"
                              src="../../assets/img/23. Close.svg"
                              alt=""
                            />
                            Add</a
                          >
                        </div>
                        <carousel :per-page="1" :mouse-drag="true">
                          <slide v-for="trade in trades" :key="trade.lti_id">
                            <div class="vehicle-img">
                              <img
                                class="img-fluid"
                                v-on:click.stop.prevent="
                                  trade_vehicleDetail(trade)
                                "
                                src="../../assets/img/carplaceholder.png"
                                alt=""
                              />
                            </div>

                            <div class="vehicle-table">
                              <h4>
                                {{ trade.lti_vehicle_make }}

                                {{ trade.lti_vehicle_model }}

                                {{ trade.lti_vehicle_year }}
                              </h4>

                              <table class="table">
                                <tbody>
                                  <tr>
                                    <td>Stock#</td>

                                    <td>
                                      {{ trade.lti_vehicle_stockno }}
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>Mile</td>

                                    <td>{{ trade.lti_vehicle_mileage }}/h</td>
                                  </tr>

                                  <tr>
                                    <td>Color</td>

                                    <td>
                                      {{ trade.lti_vehicle_color }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </slide>

                          <!-- detais-title -->
                        </carousel>

                        <!-- detais-title -->
                      </div>
                    </div>
                    <!-- Vehicle-tab -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- accordion -->
        </div>

        <div class="profile-accordion">
          <!-- accordion -->

          <div class="accordion" id="accordionExample44">
            <div class="card">
              <div class="card-header" id="headingOne44">
                <h2 class="mb-0">
                  <button
                    class="btn btn-link"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseOne44"
                    aria-expanded="true"
                    aria-controls="collapseOne44"
                  >
                    <div class="detais-title">
                      <h3>
                        <img
                          class="img-fluid arrow-down"
                          src="../../assets/img/arrow-down.svg"
                          alt=""
                        />Deal Information
                      </h3>
                    </div>
                  </button>
                </h2>
              </div>

              <div
                id="collapseOne44"
                class="collapse show"
                aria-labelledby="headingOne44"
                data-parent="#accordionExample44"
              >
                <div class="card-body">
                  <!-- <div class="cus-name">
                      <table class="table">
                        <tbody>
                          <tr>
                            <td class="f">Dealer:</td>

                            <td>{{ dealer.dl_name }}</td>
                          </tr>

                          <tr>
                            <td>Sale Manager:</td>
                            <td></td>
                          </tr>

                          <tr>
                            <td>Email:</td>

                            <td>{{ dealer.dl_email }}</td>
                          </tr>

                          <tr>
                            <td>Address:</td>

                            <td>{{ dealer.dl_address }}</td>
                          </tr>

                          <tr>
                            <td>Status:</td>

                            <td>
                              <p v-if="LDetail.l_status == 1">Active</p>
                              <p v-if="LDetail.l_status == 2">Contacted</p>
                              <p v-if="LDetail.l_status == 3">Scheduled</p>
                              <p v-if="LDetail.l_status == 6">Sold</p>
                              <p v-if="LDetail.l_status == 5">Demo</p>
                              <p v-if="LDetail.l_status == 4">Visit</p>
                              <p v-if="LDetail.l_status == 7">Lost</p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                   
                  </div> -->
                  <div class="cus-name customerdetails">
                    <table class="table">
                      <tbody>
                        <tr>
                          <td>Dealer:</td>

                          <td>{{ dealer.dl_name }}</td>
                        </tr>

                        <tr>
                          <td>Sales Manager:</td>

                          <td></td>
                        </tr>

                        <tr>
                          <td>Email:</td>

                          <td>{{ dealer.dl_email }}</td>
                        </tr>

                        <tr>
                          <td>Address:</td>

                          <td>{{ dealer.dl_address }}</td>
                        </tr>

                        <!-- <tr>
                          <td>Sale No:</td>

                          <td>2925465</td>
                        </tr>

                        <tr>
                          <td>DMS No:</td>

                          <td>9846</td>
                        </tr>

                        <tr>
                          <td>Deal date:</td>

                          <td>09-29-2015</td>
                        </tr> -->

                        <!-- <tr>
                          <td>Purchase type:</td>

                          <td>
                            <select
                              style="width: 50%"
                              id="inputState"
                              class="form-control"
                            >
                              <option selected>Finance</option>

                              <option>Finance</option>

                              <option>Finance</option>

                              <option>Finance</option>
                            </select>
                          </td>
                        </tr> -->

                        <tr>
                          <td>Status:</td>

                          <td>
                            <p v-if="LDetail.l_status == 1">Active</p>
                            <p v-if="LDetail.l_status == 2">Contacted</p>
                            <p v-if="LDetail.l_status == 3">Scheduled</p>
                            <p v-if="LDetail.l_status == 6">Sold</p>
                            <p v-if="LDetail.l_status == 5">Demo</p>
                            <p v-if="LDetail.l_status == 4">Visit</p>
                            <p v-if="LDetail.l_status == 7">Lost</p>
                          </td>
                        </tr>
                        <tr>
                          <td>Lead ID:</td>

                          <td>
                            <router-link
                              :to="{
                                name: 'LeadDetail',
                                params: { id: $route.params.id },
                              }"
                            >
                              {{ $route.params.id }}
                            </router-link>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- accordion -->
        </div>
      </div>
    </div>
    <!-- desking-box -->

    <!-- desking-box -->
    <div class="desking-box desk-mid-box newdesking">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <a
            class="nav-link active"
            id="Worksheet-tab"
            data-toggle="tab"
            href="#Worksheet"
            role="tab"
            aria-controls="Worksheet"
            aria-selected="true"
            >Worksheet</a
          >
        </li>

        <li class="nav-item" role="presentation">
          <a
            class="nav-link"
            id="Credit-tab"
            data-toggle="tab"
            href="#Credit"
            role="tab"
            aria-controls="Credit"
            aria-selected="false"
            >Credit</a
          >
        </li>
        <li class="nav-item" role="presentation">
          <a
            class="nav-link"
            id="Print-tab"
            data-toggle="tab"
            href="#Print"
            role="tab"
            aria-controls="Print"
            aria-selected="false"
            >Forms</a
          >
        </li>
      </ul>

      <div class="views">
        <ul>
          <li
            v-if="
              this.$storage.get('auth').user.email == 'manager@genevamotors.com'
            "
          >
            <button
              :disabled="dmsLoader"
              type="submit"
              @click="sendtodms"
              class="rem-btn"
            >
              <span v-if="dmsLoader"
                ><span class="spinner-grow spinner-grow-sm"></span>
                Loading...</span
              >
              <span v-if="!dmsLoader"> Send To Dms</span>
            </button>
          </li>
          <li>
            <a
              href="#"
              @click="preview"
              data-toggle="modal"
              data-target="#exampleModal50"
              ><img class="img-fluid" src="../../assets/img/Button.png" alt=""
            /></a>
          </li>
        </ul>
      </div>

      <div class="tab-content" id="myTabContent">
        <div
          class="tab-pane fade show active"
          id="Worksheet"
          role="tabpanel"
          aria-labelledby="Worksheet-tab"
        >
          <form @submit.prevent="adddesking">
            <div class="add-member">
              <div class="desking box desk-radio">
                <div class="accordion" id="accordionExample14">
                  <div class="card">
                    <div class="card-header" id="headingOne14"></div>

                    <div
                      id="collapseOne14"
                      class="collapse show"
                      aria-labelledby="headingOne14"
                      data-parent="#accordionExample14"
                    >
                      <div class="card-body"></div>

                      <div class="form-group" style="padding: 17px">
                        <label class="containerw desk-radio1">
                          Finance

                          <input
                            type="radio"
                            @click="toggle('Finance')"
                            id="finance"
                            value="Finance"
                            name="radio"
                            :checked="finance"
                          />

                          <span class="checkmark"></span>
                        </label>

                        <label
                          v-if="voi.v_new_used_status == 'New'"
                          class="containerw desk-radio2"
                        >
                          Lease

                          <input
                            type="radio"
                            @click="toggle('Lease')"
                            id="lease"
                            value="Lease"
                            name="radio"
                          />

                          <span class="checkmark"></span>
                        </label>

                        <label class="containerw desk-radio1">
                          Cash

                          <input
                            type="radio"
                            @click="toggle('Cash')"
                            id="cash"
                            value="Cash"
                            name="radio"
                          />

                          <span class="checkmark"></span>
                        </label>

                        <div class="finance-box-area" v-if="finance">
                          <div class="finner-inner">
                            <!-- finece-timeline -->
                            <div class="finece-timeline">
                              <div class="finece-box">
                                <p>Payment 1</p>
                                <h3>${{ downpayment1 | roundoff }}</h3>
                              </div>

                              <div class="finece-box">
                                <p>Payment 2</p>
                                <h3>${{ downpayment2 | roundoff }}</h3>
                              </div>
                              <div class="finece-box">
                                <p>Payment 3</p>
                                <h3>${{ downpayment3 | roundoff }}</h3>
                              </div>
                            </div>
                          </div>

                          <!-- finece-option -->
                        </div>
                        <div class="Lease-box-area" v-if="lease">
                          <div class="finner-inner" v-if="onepaylease == 0">
                            <!-- finece-timeline -->
                            <div class="finece-timeline lease-timeline">
                              <div class="finece-box">
                                <p>Payment 1</p>
                                <h3>${{ lease_downpayment1 | roundoff }}</h3>
                              </div>
                              <div class="finece-box">
                                <p>Payment 2</p>
                                <h3>${{ lease_downpayment2 | roundoff }}</h3>
                              </div>
                              <div class="finece-box">
                                <p>Payment 3</p>
                                <h3>${{ lease_downpayment3 | roundoff }}</h3>
                              </div>
                            </div>
                          </div>
                          <div class="finner-inner cash-box" v-else>
                            <!-- finece-timeline -->
                            <div class="finece-timeline">
                              <div class="finece-box">
                                <p style="color: #3151a1 !important">
                                  Selling Price
                                </p>
                                <p style="color: #3151a1 !important">
                                  ${{ cash_selling_price }}
                                </p>
                              </div>

                              <div class="finece-box">
                                <p>Payment</p>
                                <h3 v-if="desking.onePayLeaseplaceholder">
                                  ${{
                                    desking.onePayLeaseplaceholder | roundoff
                                  }}
                                </h3>
                              </div>

                              <div class="finece-box">
                                <p style="color: #3151a1 !important">
                                  Selling Price
                                </p>
                                <h3 style="color: #3151a1 !important">
                                  ${{ cash_selling_price }}
                                </h3>
                              </div>
                            </div>
                          </div>
                          <!-- finece-option -->
                        </div>
                        <div class="finance-box-area" v-if="deal_type">
                          <div class="finner-inner cash-box">
                            <!-- finece-timeline -->
                            <div class="finece-timeline">
                              <div class="finece-box">
                                <p style="color: #3151a1 !important">
                                  Selling Price
                                </p>
                                <p style="color: #3151a1 !important">
                                  ${{ cash_selling_price }}
                                </p>
                              </div>

                              <div class="finece-box">
                                <p>Selling Price</p>
                                <h3 v-if="cash_selling_price_placeholder">
                                  ${{ cash_selling_price_placeholder }}
                                </h3>
                              </div>

                              <div class="finece-box">
                                <p style="color: #3151a1 !important">
                                  Selling Price
                                </p>
                                <h3 style="color: #3151a1 !important">
                                  ${{ cash_selling_price }}
                                </h3>
                              </div>
                            </div>
                          </div>

                          <!-- finece-option -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <br /><br />

              <div class="profile-accordion">
                <!-- accordion -->

                <div class="accordion" id="accordionExample1">
                  <div class="card">
                    <div class="card-header" id="headingOne1">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseOne1"
                          aria-expanded="true"
                          aria-controls="collapseOne1"
                        >
                          <div class="detais-title">
                            <h3>
                              <img
                                class="img-fluid arrow-down"
                                src="../../assets/img/arrow-down.svg"
                                alt=""
                              />Selling Price
                            </h3>
                          </div>
                        </button>
                      </h2>

                      <vue-numeric
                        v-if="finance"
                        currency="$"
                        separator=","
                        class="form-control pull-right"
                        v-model="selling_price_placeholder"
                        :read-only="true"
                        read-only-class="price-placeholder"
                      ></vue-numeric>

                      <vue-numeric
                        v-if="lease"
                        currency="$"
                        separator=","
                        class="form-control pull-right"
                        v-model="lease_selling_price_placeholder"
                        :read-only="true"
                        read-only-class="price-placeholder"
                      ></vue-numeric>

                      <vue-numeric
                        v-if="deal_type"
                        currency="$"
                        separator=","
                        class="form-control pull-right"
                        v-model="cash_selling_price_placeholder"
                        :read-only="true"
                        read-only-class="price-placeholder"
                      ></vue-numeric>
                    </div>

                    <div
                      id="collapseOne1"
                      class="collapse show"
                      aria-labelledby="headingOne1"
                      data-parent="#accordionExample1"
                    >
                      <div class="card-body">
                        <div class="cus-name">
                          <div class="row">
                            <div class="col-md-6" v-if="finance">
                              <label for="inputEmail4">MSRP</label>
                              <vue-numeric
                                currency="$"
                                @input="MainCalculationFinance"
                                v-model="desking.l_finance_msrp"
                                class="form-control"
                                separator=","
                                v-bind:precision="2"
                              ></vue-numeric>
                            </div>

                            <div class="col-md-6" v-if="lease">
                              <label for="inputEmail4">MSRP</label>
                              <vue-numeric
                                currency="$"
                                @input="MainCalculationLease"
                                class="form-control"
                                v-model="desking.l_lease_msrp"
                                separator=","
                                v-bind:precision="2"
                              ></vue-numeric>
                            </div>

                            <div class="col-md-6" v-if="finance">
                              <label for="inputEmail4">Selling Price</label>

                              <vue-numeric
                                currency="$"
                                @input="MainCalculationFinance"
                                class="form-control"
                                v-model="selling_price"
                                separator=","
                                v-bind:precision="2"
                              ></vue-numeric>
                            </div>

                            <div class="col-md-6" v-if="lease">
                              <label for="inputEmail4">Selling Price</label>

                              <vue-numeric
                                currency="$"
                                @input="MainCalculationLease"
                                class="form-control"
                                v-model="lease_selling_price"
                                separator=","
                                v-bind:precision="2"
                              ></vue-numeric>
                            </div>

                            <div class="col-md-6" v-if="deal_type">
                              <label for="inputEmail4">Selling Price</label>

                              <vue-numeric
                                currency="$"
                                @input="MainCalculationCash"
                                class="form-control"
                                v-model="cash_selling_price"
                                separator=","
                                v-bind:precision="2"
                              ></vue-numeric>
                            </div>

                            <div class="col-md-6" v-if="finance">
                              <div class="row">
                                <div
                                  class="col-md-4"
                                  style="padding-right: 0px"
                                >
                                  <label class="cash">Cash D1</label>

                                  <vue-numeric
                                    currency="$"
                                    class="form-control"
                                    @input="MainCalculationFinance"
                                    v-bind:precision="2"
                                    v-model="finance_10percent"
                                    separator=","
                                  ></vue-numeric>
                                </div>

                                <div
                                  class="col-md-4"
                                  style="padding-right: 0px"
                                >
                                  <label class="cash">Cash D2</label>

                                  <vue-numeric
                                    currency="$"
                                    class="form-control"
                                    v-bind:precision="2"
                                    @input="MainCalculationFinance"
                                    v-model="finance_15percent"
                                    separator=","
                                  ></vue-numeric>
                                </div>

                                <div class="col-md-4">
                                  <label class="cash">Cash D3</label>

                                  <vue-numeric
                                    currency="$"
                                    class="form-control"
                                    @input="MainCalculationFinance"
                                    v-bind:precision="2"
                                    v-model="finance_20percent"
                                    separator=","
                                  ></vue-numeric>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6" v-if="lease">
                              <div class="row">
                                <div
                                  class="col-md-4"
                                  style="padding-right: 0px"
                                >
                                  <label class="cash">Cap Red 1</label>
                                  <vue-numeric
                                    currency="$"
                                    class="form-control"
                                    @input="MainCalculationLease"
                                    v-bind:precision="2"
                                    v-model="lease_10percent"
                                    separator=","
                                  ></vue-numeric>
                                </div>

                                <div
                                  class="col-md-4"
                                  style="padding-right: 0px"
                                >
                                  <label class="cash">Cap Red 2</label>
                                  <vue-numeric
                                    currency="$"
                                    class="form-control"
                                    @input="MainCalculationLease"
                                    v-bind:precision="2"
                                    v-model="lease_15percent"
                                    separator=","
                                  ></vue-numeric>
                                </div>

                                <div class="col-md-4">
                                  <label class="cash">Cap Red 3</label>
                                  <vue-numeric
                                    currency="$"
                                    class="form-control"
                                    @input="MainCalculationLease"
                                    v-bind:precision="2"
                                    v-model="lease_20percent"
                                    separator=","
                                  ></vue-numeric>
                                </div>
                              </div>
                            </div>

                            <div
                              class="col-md-6"
                              style="margin-top: -2px"
                              v-if="finance"
                            >
                              <label for="inputEmail4">Terms</label>

                              <input
                                type="text"
                                @input="MainCalculationFinance"
                                class="form-control"
                                v-model="financec_terms"
                              />
                            </div>

                            <div
                              class="col-md-6"
                              style="margin-top: 17px"
                              v-if="lease"
                            >
                              <label for="inputEmail4">Terms</label>

                              <input
                                type="text"
                                @input="MainCalculationLease"
                                class="form-control"
                                v-model="lease_terms"
                              />
                            </div>

                            <div class="col-md-6" v-if="finance">
                              <label for="inputEmail4">Net Trade</label>
                              <vue-numeric
                                currency="$"
                                class="form-control"
                                @input="MainCalculationFinance"
                                v-model="desking.l_finance_net_trade"
                                v-bind:precision="2"
                                separator=","
                              ></vue-numeric>
                            </div>

                            <div class="col-md-6" v-if="deal_type">
                              <label for="inputEmail4">Net Trade</label>
                              <vue-numeric
                                currency="$"
                                class="form-control"
                                @input="MainCalculationCash"
                                v-model="desking.l_cash_net_trade"
                                v-bind:precision="2"
                                separator=","
                              ></vue-numeric>
                            </div>

                            <div class="col-md-6" v-if="lease">
                              <label for="inputEmail4">Net Trade</label>
                              <vue-numeric
                                currency="$"
                                class="form-control"
                                @input="MainCalculationLease"
                                v-model="desking.l_lease_net_Trade"
                                v-bind:precision="2"
                                separator=","
                              ></vue-numeric>
                            </div>

                            <div class="col-md-6" v-if="finance">
                              <label for="inputEmail4">Rebate</label>
                              <vue-numeric
                                currency="$"
                                class="form-control"
                                @input="MainCalculationFinance"
                                v-model="desking.l_finannce_rebate"
                                v-bind:precision="2"
                                separator=","
                              ></vue-numeric>
                            </div>

                            <div class="col-md-6" v-if="lease">
                              <label for="inputEmail4">Rebate</label>
                              <vue-numeric
                                currency="$"
                                v-bind:precision="2"
                                class="form-control"
                                @input="MainCalculationLease"
                                v-model="desking.l_lease_rebate"
                                separator=","
                              ></vue-numeric>
                            </div>

                            <div class="col-md-6" v-if="deal_type">
                              <label for="inputEmail4">Rebate</label>
                              <vue-numeric
                                currency="$"
                                class="form-control"
                                @input="MainCalculationCash"
                                v-bind:precision="2"
                                v-model="desking.l_cash_rebate"
                                separator=","
                              ></vue-numeric>
                            </div>

                            <div class="col-md-6" v-if="finance">
                              <label for="inputEmail4">APR</label>
                              <vue-numeric
                                currency="%"
                                v-bind:precision="2"
                                class="form-control"
                                @input="MainCalculationFinance"
                                v-model="desking.l_finance_apr"
                                separator=","
                              ></vue-numeric>
                            </div>

                            <div class="col-md-6" v-if="finance">
                              <label for="inputEmail4">Amount Financed</label>
                              <vue-numeric
                                currency="$"
                                v-bind:precision="2"
                                class="form-control"
                                @input="MainCalculationFinance"
                                :value="finance_amount"
                                separator=","
                              ></vue-numeric>
                            </div>

                            <div class="col-md-6" v-if="lease">
                              <label for="inputEmail4">APR</label>
                              <vue-numeric
                                currency="%"
                                class="form-control"
                                v-bind:precision="2"
                                @input="MainCalculationLease"
                                v-model="desking.l_lease_apr"
                                separator=","
                              ></vue-numeric>
                            </div>

                            
                            <div class="col-md-6" v-if="lease">
                              <label for="inputEmail4">Amount Financed</label>
                              <vue-numeric
                                currency="$"
                                v-bind:precision="2"
                                class="form-control"
                                @input="MainCalculationFinance"
                                :value="lease_finance_amount"
                                separator=","
                              ></vue-numeric>
                            </div>

                            <div class="col-md-6" v-if="lease">
                              <div class="row" style="margin-top: 24px">
                                <div class="col-md-6">
                                  <label>One Pay Lease</label>
                                </div>
                                <div class="col-md-6">
                                  <span class="deal-type taxes-switch switch"
                                    ><label label="" class="switch">
                                      <input
                                        type="checkbox"
                                        v-model="desking.onepaylease"
                                        @change="onChangePayLease($event)"
                                      /><span class="slider round"></span>
                                    </label>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- accordion -->
              </div>

              <div class="profile-accordion" v-if="lease">
                <!-- accordion -->

                <div class="accordion" id="accordionExample101">
                  <div class="card">
                    <div class="card-header" id="headingOne101">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseOne101"
                          aria-expanded="true"
                          aria-controls="collapseOne101"
                        >
                          <div class="detais-title">
                            <h3>
                              <img
                                class="img-fluid arrow-down"
                                src="../../assets/img/arrow-down.svg"
                                alt=""
                              />Adjusted Cap Cost
                            </h3>
                          </div>
                        </button>
                      </h2>
                    </div>

                    <div
                      id="collapseOne101"
                      class="collapse show"
                      aria-labelledby="headingOne101"
                      data-parent="#accordionExample101"
                    >
                      <div class="card-body">
                        <div class="cus-name">
                          <div class="row">
                            <div class="col-md-6">
                              <label for="inputEmail4">Leasing Company</label>

                              <select
                                @change="onChangeLender"
                                class="form-control"
                                v-model="desking.l_lease_vsc_lease_company"
                              >
                                <option
                                  v-for="lenderdata in lenderdata"
                                  :key="lenderdata.id"
                                  :value="lenderdata.id"
                                >
                                  {{ lenderdata.source_name }}
                                </option>
                              </select>
                            </div>

                            <div class="col-md-6">
                              <label for="inputEmail4">Terms</label>

                              <vue-numeric
                                class="form-control"
                                @input="MainCalculationLease"
                                v-model="lease_terms"
                              ></vue-numeric>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- accordion -->
              </div>

              <!--PROGRAM Miles -->

              <div class="profile-accordion" v-if="lease">
                <!-- accordion -->

                <div class="accordion" id="accordionExample102">
                  <div class="card">
                    <div class="card-header" id="headingOne102">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseOne102"
                          aria-expanded="true"
                          aria-controls="collapseOne102"
                        >
                          <div class="detais-title">
                            <h3>
                              <img
                                class="img-fluid arrow-down"
                                src="../../assets/img/arrow-down.svg"
                                alt=""
                              />Program Miles
                            </h3>
                          </div>
                        </button>
                      </h2>

                      <vue-numeric
                        currency="mi"
                        class="form-control pull-right"
                        v-if="lender"
                        v-bind:precision="2"
                        v-model="lender.default_milage"
                        :read-only="true"
                        read-only-class="price-placeholder"
                      ></vue-numeric>
                    </div>

                    <div
                      id="collapseOne102"
                      class="collapse show"
                      aria-labelledby="headingOne102"
                      data-parent="#accordionExample102"
                    >
                      <div class="card-body">
                        <div class="cus-name">
                          <div class="row">
                            <div class="col-md-6">
                              <div class="row">
                                <div class="col-md-6">
                                  <label for="inputEmail4">Residual Rate</label>
                                  <vue-numeric
                                    currency="%"
                                    class="form-control"
                                    @input="MainCalculationLease"
                                    v-bind:precision="2"
                                    v-model="l_lease_residual"
                                    separator=","
                                  ></vue-numeric>
                                </div>
                                <div class="col-md-6">
                                  <label for="inputEmail4">Placeholder</label>
                                  <input
                                    type="number"
                                    class="form-control"
                                    v-model="desking.placeholder"
                                    readonly
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <label for="inputEmail4">Money Factor</label>
                              <vue-numeric
                                currency=""
                                class="form-control"
                                v-bind:precision="6"
                                v-model="desking.l_lease_moneyfactor"
                                separator=","
                              ></vue-numeric>
                            </div>

                            <div class="col-md-6">
                              <label for="inputEmail4">Security Deposit</label>
                              <vue-numeric
                                currency="$"
                                class="form-control"
                                v-bind:precision="2"
                                v-model="desking.l_lease_Seqrity_DEP"
                                separator=","
                              ></vue-numeric>
                            </div>
                          </div>
                          <div
                            class="row"
                            style="margin-top: 30px; margin-bottom: 30px"
                          >
                            <div class="col-md-6">
                              <a
                                @click="openRollpaymentModal"
                                class="rollpayment"
                                >Roll Payment</a
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- accordion -->
              </div>

              <div class="profile-accordion">
                <!-- accordion -->

                <div class="accordion" id="accordionExample2">
                  <div class="card">
                    <div class="card-header" id="headingOne2">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseOne2"
                          aria-expanded="true"
                          aria-controls="collapseOne2"
                        >
                          <div class="detais-title">
                            <h3>
                              <img
                                class="img-fluid arrow-down"
                                src="../../assets/img/arrow-down.svg"
                                alt=""
                              />Fees
                            </h3>
                          </div>
                        </button>
                      </h2>

                      <vue-numeric
                        currency="$"
                        separator=","
                        class="form-control pull-right"
                        v-model="sum_fee"
                        :read-only="true"
                        read-only-class="price-placeholder"
                      ></vue-numeric>
                    </div>

                    <div
                      id="collapseOne2"
                      class="collapse show"
                      aria-labelledby="headingOne2"
                      data-parent="#accordionExample2"
                    >
                      <div class="card-body deskcard">
                        <div class="table-responsive-md">
                          <table>
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>Type</th>

                                <th>Tax</th>

                                <th
                                  style="text-align: right; padding-right: 30px"
                                >
                                  Amount
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              <tr v-for="fees in fees" :key="fees.id">
                                <td>
                                  <label class="containerw">
                                    {{ fees.name }}

                                    <input
                                      type="checkbox"
                                      :value="fees"
                                      :id="fees"
                                      v-model="checkfee"
                                      @change="onChangeFees"
                                    />

                                    <input
                                      type="hidden"
                                      :class="'checkbox_' + fees.id"
                                      :value="fees.ammount"
                                    />

                                    <span class="checkmark"></span>
                                  </label>
                                </td>

                                <td>
                                  <span
                                    style="color: #6d778e; font-size: 14px"
                                    >{{ fees.type }}</span
                                  >
                                </td>

                                <td>
                                  <img
                                    v-if="fees.tax == 1"
                                    src="../../assets/img/tick.svg"
                                    class="img-fluid"
                                  />

                                  <img
                                    v-else
                                    src="../../assets/img/cross.svg"
                                    class="img-fluid"
                                  />
                                </td>

                                <td style="text-align: right">
                                  <div class="doinput">
                                    <input
                                      type="text"
                                      style="width: 100px; text-align: center"
                                      class="form-control"
                                      v-model="fees.ammount"
                                      :class="'field_' + fees.id"
                                      @keyup="onChangeFees"
                                    />
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- accordion -->
              </div>

              <div class="profile-accordion">
                <!-- accordion -->

                <div class="accordion" id="accordionExample3">
                  <div class="card">
                    <div class="card-header" id="headingOne3">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseOne3"
                          aria-expanded="true"
                          aria-controls="collapseOne3"
                        >
                          <div class="detais-title">
                            <h3>
                              <img
                                class="img-fluid arrow-down"
                                src="../../assets/img/arrow-down.svg"
                                alt=""
                              />Taxes
                            </h3>
                          </div>
                        </button>
                      </h2>

                      <vue-numeric
                        currency="$"
                        separator=","
                        class="form-control pull-right"
                        v-bind:precision="2"
                        v-model="finance_tax_value"
                        @input="MainCalculationFinance"
                        v-if="finance"
                        :read-only="true"
                        read-only-class="price-placeholder"
                      ></vue-numeric>
                      <vue-numeric
                        currency="$"
                        separator=","
                        class="form-control pull-right"
                        v-bind:precision="2"
                        @input="MainCalculationLease"
                        v-model="lease_tax_value"
                        v-if="lease"
                        :read-only="true"
                        read-only-class="price-placeholder"
                      ></vue-numeric>
                      <vue-numeric
                        currency="$"
                        separator=","
                        v-bind:precision="2"
                        class="form-control pull-right"
                        @input="MainCalculationCash"
                        v-model="cash_tax_value"
                        v-if="deal_type"
                        :read-only="true"
                        read-only-class="price-placeholder"
                      ></vue-numeric>
                    </div>

                    <div
                      id="collapseOne3"
                      class="collapse show"
                      aria-labelledby="headingOne3"
                      data-parent="#accordionExample3"
                    >
                      <div class="card-body deskcard">
                        <div class="table-responsive-md">
                          <table>
                            <tbody>
                              <tr>
                                <td>
                                  <label
                                    style="font-size: 14px"
                                    class="containerw"
                                    >Sales Tax

                                    <input
                                      type="checkbox"
                                      @change="onChnageTaxRate($event)"
                                      v-model="salestax"
                                    />

                                    <span class="checkmark"></span>
                                  </label>
                                </td>

                                <td>
                                  <!-- <span class="tax">Tax</span> -->

                                  <div
                                    class="doinput"
                                    v-if="finance"
                                    style="margin-left: 30px"
                                  >
                                    <!-- <vue-numeric
                                      style="width: 100px"
                                      class="form-control"
                                      @input="MainCalculationFinance"
                                      :empty-value="0"
                                      :min="0"
                                      v-model="l_finance_tax"
                                    ></vue-numeric> -->
                                    <input
                                      type="text"
                                      style="width: 100px"
                                      placeholder="%"
                                      class="form-control"
                                      @input="MainCalculationFinance"
                                      v-model="l_finance_tax"
                                    />
                                  </div>
                                  <div
                                    class="doinput"
                                    v-if="lease"
                                    style="margin-left: 30px"
                                  >
                                    <!-- <vue-numeric
                                      currency="%"
                                      style="width: 100px"
                                      v-bind:precision="2"
                                      class="form-control"
                                      @input="MainCalculationLease"
                                      v-model="l_finance_tax"
                                      separator=","
                                    ></vue-numeric> -->
                                    <input
                                      type="text"
                                      style="width: 100px"
                                      placeholder="%"
                                      class="form-control"
                                      @input="MainCalculationLease"
                                      v-model="l_finance_tax"
                                    />
                                  </div>
                                  <div
                                    class="doinput"
                                    v-if="deal_type"
                                    style="margin-left: 30px"
                                  >
                                    <!-- <vue-numeric
                                      currency="%"
                                      style="width: 100px"
                                      v-bind:precision="2"
                                      class="form-control"
                                      @input="MainCalculationCash"
                                      v-model="l_finance_tax"
                                      separator=","
                                    ></vue-numeric> -->
                                    <input
                                      type="text"
                                      style="width: 100px"
                                      placeholder="%"
                                      class="form-control"
                                      @input="MainCalculationCash"
                                      v-model="l_finance_tax"
                                    />
                                  </div>
                                </td>

                                <td style="text-align: right">
                                  <div class="doinput" v-if="finance">
                                    <vue-numeric
                                      currency="$"
                                      style="width: 100px"
                                      v-bind:precision="2"
                                      class="form-control"
                                      v-model="finance_tax_value"
                                      @blur="getPercentageValueFinance"
                                      separator=","
                                    ></vue-numeric>
                                  </div>

                                  <div class="doinput" v-if="deal_type">
                                    <vue-numeric
                                      currency="$"
                                      style="width: 100px"
                                      v-bind:precision="2"
                                      class="form-control"
                                      v-model="cash_tax_value"
                                      @blur="getPercentageValueCash"
                                      separator=","
                                    ></vue-numeric>
                                  </div>

                                  <div class="doinput" v-if="lease">
                                    <vue-numeric
                                      currency="$"
                                      style="width: 100px"
                                      v-bind:precision="2"
                                      class="form-control"
                                      v-model="lease_tax_value"
                                      @blur="getPercentageValueLease"
                                      separator=","
                                    ></vue-numeric>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- accordion -->
              </div>

              <div class="profile-accordion">
                <!-- accordion -->

                <div class="accordion" id="accordionExample11">
                  <div class="card">
                    <div class="card-header" id="headingOne11">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseOne11"
                          aria-expanded="true"
                          aria-controls="collapseOne11"
                        >
                          <div class="detais-title">
                            <h3>
                              <img
                                class="img-fluid arrow-down"
                                src="../../assets/img/arrow-down.svg"
                                alt=""
                              />Add-Ons
                            </h3>
                          </div>
                        </button>
                      </h2>

                      <vue-numeric
                        currency="$"
                        separator=","
                        class="form-control pull-right"
                        v-model="calculateaddon"
                        :read-only="true"
                        read-only-class="price-placeholder"
                      ></vue-numeric>
                    </div>

                    <hr />

                    <div
                      id="collapseOne11"
                      class="collapse show"
                      aria-labelledby="headingOne11"
                      data-parent="#accordionExample11"
                    >
                      <div class="card-body">
                        <div class="cus-name">
                          <div>
                            <!-- accordion -->

                            <div class="accordion" id="accordionExample12">
                              <div class="card">
                                <div class="card-header" id="headingOne12">
                                  <h2 class="mb-0">
                                    <button
                                      class="btn btn-link"
                                      type="button"
                                      data-toggle="collapse"
                                      data-target="#collapseOne12"
                                      aria-expanded="true"
                                      aria-controls="collapseOne12"
                                    >
                                      <div class="detais-title">
                                        <h3>
                                          <img
                                            class="img-fluid arrow-down"
                                            src="../../assets/img/arrow-down.svg"
                                            alt=""
                                          />VSC
                                        </h3>
                                      </div>
                                    </button>
                                  </h2>

                                  <vue-numeric
                                    currency="$"
                                    separator=","
                                    v-model="vsc_placeholder"
                                    class="form-control pull-right"
                                    :read-only="true"
                                    read-only-class="price-placeholder-addon"
                                  ></vue-numeric>
                                </div>

                                <div
                                  id="collapseOne12"
                                  class="collapse"
                                  aria-labelledby="headingOne12"
                                  data-parent="#accordionExample12"
                                >
                                  <div class="card-body deskcard">
                                    <div class="table-responsive-md">
                                      <table class="table">
                                        <thead>
                                          <tr>
                                            <th>Name</th>

                                            <th>Cost</th>

                                            <th>Customer Price</th>
                                          </tr>
                                        </thead>

                                        <tbody>
                                          <tr v-for="vsc in vsc" :key="vsc.id">
                                            <td>
                                              <label class="containerw">
                                                {{ vsc.name }}

                                                <input
                                                  type="radio"
                                                  @change="
                                                    onChangevsc(
                                                      vsc.customer_price
                                                    )
                                                  "
                                                  :value="vsc.id"
                                                  v-model="vsc_value"
                                                  name="vsc"
                                                />

                                                <span class="checkmark"></span>
                                              </label>
                                            </td>

                                            <td>{{ vsc.cost }}</td>

                                            <td>
                                              <div class="doinput">
                                                <vue-numeric
                                                  currency="$"
                                                  separator=","
                                                  class="form-control"
                                                  @blur="
                                                    onChangevsccustomerprice(
                                                      vsc.customer_price
                                                    )
                                                  "
                                                  v-model="vsc.customer_price"
                                                  style="width: 100px"
                                                ></vue-numeric>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <!-- accordion -->

                            <div
                              class="accordion"
                              id="accordionExample100"
                              style="margin-top: 50px"
                            >
                              <div class="card">
                                <div class="card-header" id="headingOne100">
                                  <h2 class="mb-0">
                                    <button
                                      class="btn btn-link"
                                      type="button"
                                      data-toggle="collapse"
                                      data-target="#collapseOne100"
                                      aria-expanded="true"
                                      aria-controls="collapseOne100"
                                    >
                                      <div class="detais-title">
                                        <h3>
                                          <img
                                            class="img-fluid arrow-down"
                                            src="../../assets/img/arrow-down.svg"
                                            alt=""
                                          />Maintenance
                                        </h3>
                                      </div>
                                    </button>
                                  </h2>

                                  <vue-numeric
                                    currency="$"
                                    separator=","
                                    class="form-control pull-right"
                                    v-model="maintenance_placeholder"
                                    :read-only="true"
                                    read-only-class="price-placeholder-addon"
                                  ></vue-numeric>
                                </div>

                                <div
                                  id="collapseOne100"
                                  class="collapse"
                                  aria-labelledby="headingOne100"
                                  data-parent="#accordionExample100"
                                >
                                  <div class="card-body deskcard">
                                    <div class="table-responsive-md">
                                      <table class="table">
                                        <thead>
                                          <tr>
                                            <th>Name</th>

                                            <th>Cost</th>

                                            <th>Customer Price</th>
                                          </tr>
                                        </thead>

                                        <tbody>
                                          <tr
                                            v-for="maintenance in maintenance"
                                            :key="maintenance.id"
                                          >
                                            <td>
                                              <label class="containerw"
                                                >{{ maintenance.name }}

                                                <input
                                                  type="radio"
                                                  @change="
                                                    onChangemaintenance(
                                                      maintenance.customer_price
                                                    )
                                                  "
                                                  :value="maintenance.id"
                                                  v-model="maintenance_val"
                                                  name="main"
                                                />

                                                <span class="checkmark"></span>
                                              </label>
                                            </td>

                                            <td>
                                              {{ maintenance.cost }}
                                            </td>

                                            <td>
                                              <div class="doinput">
                                                <vue-numeric
                                                  currency="$"
                                                  separator=","
                                                  class="form-control"
                                                  @blur="
                                                    onChangemaintenancecustomerprice(
                                                      maintenance.customer_price
                                                    )
                                                  "
                                                  v-model="
                                                    maintenance.customer_price
                                                  "
                                                  style="width: 100px"
                                                ></vue-numeric>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <!-- GAp-->

                            <div
                              class="accordion"
                              id="accordionExample15"
                              style="margin-top: 50px"
                            >
                              <div class="card">
                                <div class="card-header" id="headingOne15">
                                  <h2 class="mb-0">
                                    <button
                                      class="btn btn-link"
                                      type="button"
                                      data-toggle="collapse"
                                      data-target="#collapseOne15"
                                      aria-expanded="true"
                                      aria-controls="collapseOne15"
                                    >
                                      <div class="detais-title">
                                        <h3>
                                          <img
                                            class="img-fluid arrow-down"
                                            src="../../assets/img/arrow-down.svg"
                                            alt=""
                                          />Gap
                                        </h3>
                                      </div>
                                    </button>
                                  </h2>

                                  <vue-numeric
                                    currency="$"
                                    separator=","
                                    class="form-control pull-right"
                                    v-model="gap_placeholder"
                                    :read-only="true"
                                    read-only-class="price-placeholder-addon"
                                  ></vue-numeric>
                                </div>

                                <div
                                  id="collapseOne15"
                                  class="collapse"
                                  aria-labelledby="headingOne15"
                                  data-parent="#accordionExample15"
                                >
                                  <div class="card-body deskcard">
                                    <div class="table-responsive-md">
                                      <table class="table">
                                        <thead>
                                          <tr>
                                            <th>Name</th>

                                            <th>Cost</th>

                                            <th>Customer Price</th>
                                          </tr>
                                        </thead>

                                        <tbody>
                                          <tr v-for="gap in gap" :key="gap.id">
                                            <td>
                                              <label class="containerw">
                                                {{ gap.name }}

                                                <input
                                                  type="radio"
                                                  :value="gap.id"
                                                  @change="
                                                    onChangeGap(
                                                      gap.customer_price
                                                    )
                                                  "
                                                  v-model="gap_value"
                                                  name="gap"
                                                />

                                                <span class="checkmark"></span>
                                              </label>
                                            </td>

                                            <td>{{ gap.cost }}</td>

                                            <td>
                                              <div class="doinput">
                                                <vue-numeric
                                                  currency="$"
                                                  separator=","
                                                  class="form-control"
                                                  @blur="
                                                    onChangegapcustomerprice(
                                                      gap.customer_price
                                                    )
                                                  "
                                                  v-model="gap.customer_price"
                                                  style="width: 100px"
                                                ></vue-numeric>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <!-- after market-->

                            <div
                              class="accordion"
                              id="accordionExample16"
                              style="margin-top: 50px"
                            >
                              <div class="card">
                                <div class="card-header" id="headingOne16">
                                  <h2 class="mb-0">
                                    <button
                                      class="btn btn-link"
                                      type="button"
                                      data-toggle="collapse"
                                      data-target="#collapseOne16"
                                      aria-expanded="true"
                                      aria-controls="collapseOne16"
                                    >
                                      <div class="detais-title">
                                        <h3>
                                          <img
                                            class="img-fluid arrow-down"
                                            src="../../assets/img/arrow-down.svg"
                                            alt=""
                                          />After Market
                                        </h3>
                                      </div>
                                    </button>
                                  </h2>

                                  <vue-numeric
                                    currency="$"
                                    separator=","
                                    v-model="aftermarket_placeholder"
                                    class="form-control pull-right"
                                    :read-only="true"
                                    read-only-class="price-placeholder-addon"
                                  ></vue-numeric>
                                </div>

                                <div
                                  id="collapseOne16"
                                  class="collapse"
                                  aria-labelledby="headingOne16"
                                  data-parent="#accordionExample16"
                                >
                                  <div class="card-body deskcard">
                                    <div class="servicesinfo-box">
                                      <div class="sortable-items">
                                        <form
                                          role="form"
                                          action=""
                                          method="POST"
                                        >
                                          <div
                                            class="
                                              multi-field-wrapper2
                                              table-responsive-md
                                            "
                                          >
                                            <div class="table-responsive-md">
                                              <table
                                                class="table desksettig table2"
                                              >
                                                <thead>
                                                  <tr>
                                                    <th>Service</th>
                                                    <th
                                                      style="text-align: center"
                                                    >
                                                      Cost $
                                                    </th>
                                                    <th
                                                      style="text-align: center"
                                                    >
                                                      Price $
                                                    </th>
                                                    <th></th>
                                                  </tr>
                                                </thead>

                                                <tbody
                                                  class="
                                                    multi-fields2
                                                    sortable
                                                    ui-sortable
                                                  "
                                                >
                                                  <tr
                                                    class="
                                                      ui-state-default
                                                      multi-field2
                                                      ui-sortable-handle
                                                    "
                                                    v-for="(
                                                      aftermarketdata, counter
                                                    ) in aftermarketdata"
                                                    :key="aftermarketdata.id"
                                                  >
                                                    <td>
                                                      <div class="names">
                                                        <span class="ddd"
                                                          ><img
                                                            class="img-fluid"
                                                            src="assets/img/dots.svg"
                                                            alt=""
                                                        /></span>

                                                        <p>
                                                          {{
                                                            aftermarketdata.name
                                                          }}
                                                        </p>
                                                      </div>
                                                    </td>

                                                    <td
                                                      style="text-align: center"
                                                    >
                                                      <input
                                                        type="number"
                                                        class="form-control"
                                                        style="
                                                          background: #f6f7fa;
                                                        "
                                                        v-model="
                                                          aftermarketdata.cost
                                                        "
                                                      />
                                                    </td>

                                                    <td
                                                      style="text-align: center"
                                                    >
                                                      <input
                                                        type="number"
                                                        class="form-control"
                                                        style="
                                                          background: #f6f7fa;
                                                        "
                                                        v-model="
                                                          aftermarketdata.customer_price
                                                        "
                                                      />
                                                    </td>

                                                    <td>
                                                      <img
                                                        @click="
                                                          removeaftermarket(
                                                            aftermarketdata.id
                                                          )
                                                        "
                                                        style="
                                                          padding-right: 11px;
                                                        "
                                                        class="remove-field2"
                                                        src="../../assets/img/closs.svg"
                                                        alt=""
                                                      />

                                                      <img
                                                        @click="
                                                          updatemarketplacee(
                                                            counter
                                                          )
                                                        "
                                                        class="remove-field2"
                                                        src="../../assets/img/tick.svg"
                                                        alt=""
                                                      />
                                                    </td>
                                                  </tr>

                                                  <tr
                                                    class="
                                                      ui-state-default
                                                      multi-field2
                                                      ui-sortable-handle
                                                    "
                                                    v-for="(
                                                      aftermarket, counter
                                                    ) in aftermarket"
                                                    v-bind:key="counter"
                                                  >
                                                    <td>
                                                      <div class="names">
                                                        <span class="ddd"
                                                          ><img
                                                            class="img-fluid"
                                                            src="assets/img/dots.svg"
                                                            alt=""
                                                        /></span>

                                                        <select
                                                          v-if="
                                                            aftermarket.name
                                                          "
                                                          :id="
                                                            'select_' + counter
                                                          "
                                                          class="form-control"
                                                          v-model="
                                                            aftermarket.name
                                                          "
                                                          style="width: 152px"
                                                          @change="
                                                            CustomService(
                                                              aftermarket.name,
                                                              counter
                                                            )
                                                          "
                                                        >
                                                          <option
                                                            value="Change Tires"
                                                          >
                                                            Change Tires
                                                          </option>

                                                          <option
                                                            value="Replace Engine Oil & Oil Filter"
                                                          >
                                                            Replace Engine Oil &
                                                            Oil Filter
                                                          </option>

                                                          <option
                                                            value="Replace Engine"
                                                          >
                                                            Replace Engine
                                                          </option>

                                                          <option
                                                            value="Change Tires"
                                                          >
                                                            Change Tires
                                                          </option>
                                                          <option
                                                            value="custom"
                                                          >
                                                            Custom
                                                          </option>
                                                        </select>
                                                        <input
                                                          v-else
                                                          type="text"
                                                          class="form-control"
                                                          style="
                                                            background: #f6f7fa;
                                                          "
                                                          v-model="
                                                            aftermarket.service
                                                          "
                                                        />
                                                      </div>
                                                    </td>

                                                    <td>
                                                      <input
                                                        type="text"
                                                        class="form-control"
                                                        style="
                                                          background: #f6f7fa;
                                                        "
                                                        v-model="
                                                          aftermarket.cost
                                                        "
                                                      />
                                                    </td>

                                                    <td>
                                                      <input
                                                        type="text"
                                                        class="form-control"
                                                        style="
                                                          background: #f6f7fa;
                                                        "
                                                        @blur="
                                                          updatemarketplace(
                                                            counter
                                                          )
                                                        "
                                                        v-model="
                                                          aftermarket.customer_price
                                                        "
                                                      />
                                                    </td>

                                                    <td>
                                                      <img
                                                        @click="
                                                          deletemarketplace(
                                                            counter
                                                          )
                                                        "
                                                        class="remove-field2"
                                                        src="../../assets/img/closs.svg"
                                                        alt=""
                                                      />
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>

                                              <a
                                                @click="addmarketplace"
                                                class="btn addmore"
                                                >Add Item</a
                                              >

                                              <div class="totals">
                                                <ul>
                                                  <li>Total</li>

                                                  <li>
                                                    {{
                                                      aftermarket_placeholder
                                                    }}
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="profile-accordion" v-if="lease">
                <!-- accordion -->

                <div class="accordion" id="accordionExample201">
                  <div class="card">
                    <div class="card-header" id="headingOne201">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseOne201"
                          aria-expanded="true"
                          aria-controls="collapseOne201"
                        >
                          <div class="detais-title">
                            <h3>
                              <img
                                class="img-fluid arrow-down"
                                src="../../assets/img/arrow-down.svg"
                                alt=""
                              />Drives Offs
                            </h3>
                          </div>
                        </button>
                      </h2>

                      <vue-numeric
                        currency="$"
                        separator=","
                        class="form-control pull-right"
                        v-model="sum_fee"
                        :read-only="true"
                        read-only-class="price-placeholder"
                      ></vue-numeric>
                    </div>

                    <div
                      id="collapseOne201"
                      class="collapse show"
                      aria-labelledby="headingOne201"
                      data-parent="#accordionExample201"
                    >
                      <div class="card-body deskcard">
                        <div class="accordion" id="accordionExample2000">
                          <div class="card">
                            <div class="card-header" id="headingOne2000">
                              <h2 class="mb-0">
                                <button
                                  class="btn btn-link"
                                  type="button"
                                  data-toggle="collapse"
                                  data-target="#collapseOne2000"
                                  aria-expanded="true"
                                  aria-controls="collapseOne2000"
                                >
                                  <div class="detais-title">
                                    <h3>
                                      <img
                                        class="img-fluid arrow-down"
                                        src="../../assets/img/arrow-down.svg"
                                        alt=""
                                      />First Payment, Acq. Fee, and 5 more
                                    </h3>
                                  </div>
                                </button>
                              </h2>

                              <vue-numeric
                                currency="$"
                                separator=","
                                class="form-control pull-right"
                                v-model="sum_fee"
                                :read-only="true"
                                read-only-class="price-placeholder"
                              ></vue-numeric>
                            </div>

                            <div
                              id="collapseOne2000"
                              class="collapse show"
                              aria-labelledby="headingOne2000"
                              data-parent="#accordionExample2000"
                            >
                              <div class="card-body deskcard">
                                <div class="table-responsive-md">
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>Item</th>
                                        <th>Type</th>
                                        <th>Tax</th>
                                        <th>Ammount</th>
                                      </tr>
                                    </thead>

                                    <tbody>
                                      <tr v-for="fee in checkfee" :key="fee.id">
                                        <td>
                                          <label class=""
                                            >{{ fee.name }}
                                          </label>
                                        </td>
                                        <td>
                                          <label style="color: #343945" class=""
                                            >{{ fee.type }}
                                          </label>
                                        </td>
                                        <td>
                                          <img
                                            v-if="fee.tax == 1"
                                            src="../../assets/img/tick.svg"
                                            class="img-fluid"
                                          />

                                          <img
                                            v-if="fee.tax == 0"
                                            src="../../assets/img/cross.svg"
                                            class="img-fluid"
                                          />
                                        </td>
                                        <td>
                                          {{ fee.ammount }}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- accordion -->
              </div>

              <div class="profile-accordion" v-if="lease">
                <!-- accordion -->

                <div class="accordion" id="accordionExample202">
                  <div class="card">
                    <div class="card-header" id="headingOne202">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseOne202"
                          aria-expanded="true"
                          aria-controls="collapseOne202"
                        >
                          <div class="detais-title">
                            <h3>
                              <img
                                class="img-fluid arrow-down"
                                src="../../assets/img/arrow-down.svg"
                                alt=""
                              />Equity Distribution
                            </h3>
                          </div>
                        </button>
                      </h2>

                      <hr class="divider" />
                    </div>

                    <div
                      id="collapseOne202"
                      class="collapse show"
                      aria-labelledby="headingOne202"
                      data-parent="#accordionExample202"
                    >
                      <div class="card-body deskcard">
                        <div class="profile-accordion">
                          <div class="accordion" id="accordionExample2010">
                            <div class="card">
                              <div class="card-header" id="headingOne2010">
                                <h2 class="mb-0">
                                  <button
                                    class="btn btn-link"
                                    type="button"
                                    data-toggle="collapse"
                                    data-target="#collapseOne2010"
                                    aria-expanded="true"
                                    aria-controls="collapseOne2010"
                                  >
                                    <div class="detais-title">
                                      <h3>
                                        <img
                                          class="img-fluid arrow-down"
                                          src="../../assets/img/arrow-down.svg"
                                          alt=""
                                        />Customer Cash, Rebate, and Trade
                                      </h3>
                                    </div>
                                  </button>
                                </h2>
                              </div>

                              <div
                                id="collapseOne2010"
                                class="collapse show"
                                aria-labelledby="headingOne2010"
                                data-parent="#accordionExample2010"
                              >
                                <div class="card-body deskcard">
                                  <div class="table-responsive-md">
                                    <table>
                                      <thead></thead>

                                      <tbody>
                                        <tr>
                                          <td>
                                            <label class="containerw"
                                              >Cash
                                            </label>
                                          </td>
                                          <td style="width: 50%">
                                            <div style="display: flex">
                                              <label class="containerw"
                                                >${{ lease_10percent }}
                                                <input
                                                  style="display: none"
                                                  type="checkbox"
                                                />
                                                <span
                                                  style="display: none"
                                                  class="checkmark"
                                                ></span>
                                              </label>

                                              <label class="containerw"
                                                >D/O
                                                <input
                                                  type="checkbox"
                                                  checked
                                                />
                                                <span class="checkmark"></span>
                                              </label>

                                              <div class="option-table">
                                                <label class="containerw">
                                                  <input
                                                    type="checkbox"
                                                    checked
                                                  />
                                                  <span
                                                    class="checkmark"
                                                  ></span>
                                                  Cap
                                                </label>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td>
                                            <label class="containerw"
                                              >Rebate
                                            </label>
                                          </td>
                                          <td style="width: 50%">
                                            <div style="display: flex">
                                              <label class="containerw"
                                                >${{ desking.l_lease_rebate }}
                                                <input
                                                  style="display: none"
                                                  type="checkbox"
                                                />
                                                <span
                                                  style="display: none"
                                                  class="checkmark"
                                                ></span>
                                              </label>

                                              <label class="containerw"
                                                >D/O
                                                <input
                                                  type="checkbox"
                                                  checked
                                                />
                                                <span class="checkmark"></span>
                                              </label>

                                              <div class="option-table">
                                                <label class="containerw">
                                                  <input
                                                    type="checkbox"
                                                    checked
                                                  />
                                                  <span
                                                    class="checkmark"
                                                  ></span>
                                                  Cap
                                                </label>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>

                                        <tr>
                                          <td>
                                            <label class="containerw"
                                              >Trade in
                                            </label>
                                          </td>
                                          <td style="width: 50%">
                                            <div style="display: flex">
                                              <label class="containerw"
                                                >${{
                                                  desking.l_lease_net_Trade
                                                }}
                                                <input
                                                  style="display: none"
                                                  type="checkbox"
                                                />
                                                <span
                                                  style="display: none"
                                                  class="checkmark"
                                                ></span>
                                              </label>

                                              <label class="containerw"
                                                >D/O
                                                <input
                                                  type="checkbox"
                                                  checked
                                                />
                                                <span class="checkmark"></span>
                                              </label>

                                              <div class="option-table">
                                                <label class="containerw">
                                                  <input
                                                    type="checkbox"
                                                    checked
                                                  />
                                                  <span
                                                    class="checkmark"
                                                  ></span>
                                                  Cap
                                                </label>
                                              </div>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- accordion -->
              </div>

              <div class="profile-accordion" v-if="lease">
                <!-- accordion -->

                <div class="accordion" id="accordionExample2020">
                  <div class="card">
                    <div class="card-header" id="headingOne2020">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-link"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseOne2020"
                          aria-expanded="true"
                          aria-controls="collapseOne2020"
                        >
                          <div class="detais-title">
                            <h3>Estimated Total Gross</h3>
                          </div>
                        </button>
                      </h2>
                      <vue-numeric
                        currency="$"
                        separator=","
                        class="form-control pull-right"
                        v-model="estimatedgross"
                        :read-only="true"
                        read-only-class="price-placeholder"
                      ></vue-numeric>
                      <hr class="divider" />
                    </div>

                    <div
                      id="collapseOne2020"
                      class="collapse show"
                      aria-labelledby="headingOne2020"
                      data-parent="#accordionExample2020"
                    >
                      <div class="card-body deskcard">
                        <div class="profile-accordion">
                          <div class="accordion" id="accordionExample2022">
                            <div class="card">
                              <div class="card-header" id="headingOne2022">
                                <h2 class="mb-0">
                                  <button
                                    class="btn btn-link"
                                    type="button"
                                    data-toggle="collapse"
                                    data-target="#collapseOne2022"
                                    aria-expanded="true"
                                    aria-controls="collapseOne2022"
                                  >
                                    <div class="detais-title">
                                      <h3>Front Gross</h3>
                                    </div>
                                  </button>
                                </h2>

                                <p
                                  class="price-placeholder"
                                  style="border: none"
                                >
                                  ${{ frontgross }}
                                </p>
                              </div>

                              <div
                                id="collapseOne2022"
                                class="collapse show"
                                aria-labelledby="headingOne2022"
                                data-parent="#accordionExample2022"
                              >
                                <div class="card-body deskcard"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="profile-accordion">
                          <div class="accordion" id="accordionExample2023">
                            <div class="card">
                              <div class="card-header" id="headingOne2023">
                                <h2 class="mb-0">
                                  <button
                                    class="btn btn-link"
                                    type="button"
                                    data-toggle="collapse"
                                    data-target="#collapseOne2023"
                                    aria-expanded="true"
                                    aria-controls="collapseOne2023"
                                  >
                                    <div class="detais-title">
                                      <h3>Back Gross</h3>
                                    </div>
                                  </button>
                                </h2>

                                <p
                                  class="price-placeholder"
                                  style="border: none"
                                >
                                  $ {{ backgross }}
                                </p>
                              </div>

                              <div
                                id="collapseOne2023"
                                class="collapse show"
                                aria-labelledby="headingOne2023"
                                data-parent="#accordionExample2023"
                              >
                                <div class="card-body deskcard"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="profile-accordion">
                          <div class="accordion" id="accordionExample2023">
                            <div class="card">
                              <div class="card-header" id="headingOne2023">
                                <h2 class="mb-0">
                                  <button
                                    class="btn btn-link"
                                    type="button"
                                    data-toggle="collapse"
                                    data-target="#collapseOne2023"
                                    aria-expanded="true"
                                    aria-controls="collapseOne2023"
                                  >
                                    <div class="detais-title">
                                      <h3>Finance Gross</h3>
                                    </div>
                                  </button>
                                </h2>

                                <p
                                  class="price-placeholder"
                                  style="border: none"
                                >
                                  $ 0
                                </p>
                              </div>

                              <div
                                id="collapseOne2023"
                                class="collapse show"
                                aria-labelledby="headingOne2023"
                                data-parent="#accordionExample2023"
                              >
                                <div class="card-body deskcard"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="profile-accordion">
                          <div class="accordion" id="accordionExample2023">
                            <div class="card">
                              <div class="card-header" id="headingOne2023">
                                <h2 class="mb-0">
                                  <button
                                    class="btn btn-link"
                                    type="button"
                                    data-toggle="collapse"
                                    data-target="#collapseOne2023"
                                    aria-expanded="true"
                                    aria-controls="collapseOne2023"
                                  >
                                    <div class="detais-title">
                                      <h3>Commissionable Gross</h3>
                                    </div>
                                  </button>
                                </h2>

                                <p
                                  class="price-placeholder"
                                  style="border: none"
                                >
                                  $ 0
                                </p>
                              </div>

                              <div
                                id="collapseOne2023"
                                class="collapse show"
                                aria-labelledby="headingOne2023"
                                data-parent="#accordionExample2023"
                              >
                                <div class="card-body deskcard"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- accordion -->
              </div>
            </div>
            <button
              :disabled="btnLoader"
              type="submit"
              class="rem-btn pull-right"
            >
              <span v-if="btnLoader"
                ><span class="spinner-grow spinner-grow-sm"></span>
                Loading...</span
              >
              <span v-if="!btnLoader"> Update</span>
            </button>
            <br /><br />
          </form>
        </div>
        <div
          class="tab-pane fade"
          id="Print"
          role="tabpanel"
          aria-labelledby="Print-tab"
        >
          <!-- print -->
          <div class="print-tabs">
            <div class="row">
              <!-- single -->
              <div class="col-8">
                <div class="option-table cnnectds">
                  <div class="form-group" v-if="forms.length > 0">
                    <label class="containerw">
                      All
                      <input
                        @change="checkAllForms($event)"
                        type="checkbox"
                      /><span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-4">
                <div class="preview-btn"></div>
              </div>
              <!-- single -->

              <!-- single -->

              <div class="col-12" v-for="form in forms" :key="form.id">
                <div class="option-table cnnectds">
                  <div class="form-group">
                    <label class="containerw">
                      {{ form.title }}
                      <input
                        type="checkbox"
                        :value="form.form"
                        v-model="checkform"
                      /><span class="checkmark"></span>
                    </label>
                  </div>
                </div>
                <div class="preview-btn">
                  <a target="_blank" :href="form.form" class="prebtn"
                    >Preview</a
                  >
                  <img
                    @click="downloadPdf(form.form, form.id)"
                    style="position: relative; bottom: 25px; left: 6px"
                    class="img-fluid"
                    src="../../assets/img/save-button.svg"
                    alt=""
                  />
                  <img
                    :id="'spinner_' + form.id"
                    style="
                      position: relative;
                      bottom: 17px;
                      left: 11px;
                      width: 50px;
                      display: none;
                    "
                    class="img-fluid"
                    src="../../assets/img/Spinner.gif"
                    alt=""
                  />
                </div>
              </div>

              <!-- single -->
            </div>

            <div class="print-footer">
              <ul>
                <li>
                  <a href="#" data-toggle="modal" data-target="#exampleModal7"
                    >Add New Form</a
                  >
                </li>
                <li>
                  <a @click="printPDFS" :disabled="btnLoader" type="button">
                    <span v-if="btnLoader"
                      ><span class="spinner-grow spinner-grow-sm"></span>
                      Loading...</span
                    >
                    <span v-if="!btnLoader"> Print</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <!-- print -->
        </div>

        <div
          class="tab-pane fade"
          id="Compare"
          role="tabpanel"
          aria-labelledby="Compare-tab"
        >
          ...
        </div>
        <div
          class="tab-pane fade"
          id="Multi-deals"
          role="tabpanel"
          aria-labelledby="Multi-deals-tab"
        >
          ...
        </div>
        <div
          class="tab-pane fade"
          id="Credit"
          role="tabpanel"
          aria-labelledby="Credit-tab"
        >
          <div class="creadit-tabs">
            <form @submit.prevent="getScore">
              <div class="form-group row">
                <label for="staticEmail" class="col-sm-2 col-form-label"
                  >Company</label
                >

                <div class="col-sm-10">
                  <div class="desk-radio">
                    <div class="form-group">
                      <label class="containerw">
                        CoreLogic

                        <input
                          type="radio"
                          value="CoreLogic"
                          v-model="company"
                          name="radio"
                        />

                        <span class="checkmark"></span>
                      </label>

                      <label class="containerw">
                        700 Credit

                        <input
                          type="radio"
                          value="700 credit"
                          v-model="company"
                          checked
                          name="radio"
                        />

                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <hr />

              <div class="form-group row">
                <label for="staticEmail" class="col-sm-2 col-form-label"
                  >Bureau</label
                >

                <div class="col-sm-10">
                  <div class="desk-radio">
                    <div class="form-group">
                      <label class="containerw">
                        Experian

                        <input
                          type="radio"
                          name="radio1"
                          value="Experian"
                          @change="changeHandeler('Experian')"
                          v-model="bureu"
                          checked
                        />

                        <span class="checkmark"></span>
                      </label>

                      <label class="containerw">
                        Transunion

                        <input
                          type="radio"
                          value="Transunion"
                          @change="changeHandeler('Transunion')"
                          name="radio1"
                        />

                        <span class="checkmark"></span>
                      </label>

                      <label class="containerw">
                        Equifax

                        <input
                          type="radio"
                          value="Equifax"
                          @change="changeHandeler('Equifax')"
                          name="radio1"
                        />

                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <hr />

              <div class="form-group row">
                <label for="staticEmail" class="col-sm-2 col-form-label"
                  >Client</label
                >

                <div class="col-sm-10">
                  <div class="desk-radio">
                    <div class="form-group">
                      <label class="containerw">
                        Buyer

                        <input
                          type="radio"
                          value="Buyer"
                          v-model="client"
                          name="radio2"
                          checked
                        />

                        <span class="checkmark"></span>
                      </label>

                      <label class="containerw">
                        Spouse

                        <input
                          type="radio"
                          value="Spouse"
                          name="radio2"
                          v-model="client"
                        />

                        <span class="checkmark"></span>
                      </label>

                      <label class="containerw">
                        Joint

                        <input
                          type="radio"
                          v-model="client"
                          value="Joint"
                          name="radio2"
                        />

                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="text-center">
                <button :disabled="btnLoader" type="submit" class="submit-btn">
                  <span v-if="btnLoader"
                    ><span class="spinner-grow spinner-grow-sm"></span>

                    Loading...</span
                  >

                  <span v-if="!btnLoader"> Submit</span>
                </button>
              </div>
            </form>

            <!-- Dealership -->

            <div class="accordion" id="accordionExample33">
              <div class="card">
                <div class="card-header" id="headingOne33">
                  <h2 class="mb-0">
                    <button
                      class="btn btn-link"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseOne33"
                      aria-expanded="true"
                      aria-controls="collapseOne31"
                    >
                      <div class="detais-title">
                        <h3>
                          <img
                            class="img-fluid arrow-down"
                            src="../../assets/img/arrow-down.svg"
                            alt=""
                          />Scores
                        </h3>
                      </div>
                    </button>
                  </h2>
                </div>

                <div
                  id="collapseOne33"
                  class="collapse show"
                  aria-labelledby="headingOne33"
                  data-parent="#accordionExample33"
                >
                  <div class="card-body">
                    <div class="experian">
                      <div class="row">
                        <div class="col-sm-4">
                          <div class="experian-con border-right">
                            <p>Experian</p>

                            <h4>{{ experian_score }}</h4>
                          </div>
                        </div>

                        <div class="col-sm-4">
                          <div class="experian-con border-right">
                            <p>Transunion</p>

                            <h4>
                              {{ transunion_score }}
                            </h4>
                          </div>
                        </div>

                        <div class="col-sm-4">
                          <div class="experian-con">
                            <p>Equifax</p>

                            <h4>{{ equifax_score }}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Dealership -->

            <!-- Dealership -->

            <div class="accordion" id="accordionExample35">
              <div class="card">
                <div class="card-header" id="headingOne35">
                  <h2 class="mb-0">
                    <button
                      class="btn btn-link"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseOne35"
                      aria-expanded="true"
                      aria-controls="collapseOne31"
                    >
                      <div class="detais-title">
                        <h3>
                          <img
                            class="img-fluid arrow-down"
                            src="assets/img/arrow-down.svg"
                            alt=""
                          />Reports History
                        </h3>
                      </div>
                    </button>
                  </h2>
                </div>

                <div
                  id="collapseOne35"
                  class="collapse show"
                  aria-labelledby="headingOne35"
                  data-parent="#accordionExample35"
                >
                  <div class="card-body">
                    <!-- leads-table-area  -->

                    <div
                      class="leads-table-area report-table-area"
                      style="overflow-y: scroll; height: 400px"
                    >
                      <table class="display dataTable no-footer" id="report">
                        <thead>
                          <tr>
                            <th>Source</th>

                            <th>Bureau</th>

                            <th>Client</th>

                            <th>Date Pulled</th>

                            <th>Score</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr
                            v-for="report in report"
                            :key="report.id"
                            :id="report.id"
                            :class="'row_id_' + report.id"
                          >
                            <td class="tpButton">
                              {{ report.credit_api_company }}
                            </td>

                            <td class="tpButton">
                              {{ report.bureau }}
                            </td>

                            <td class="tpButton">
                              {{ report.client }}
                            </td>

                            <td class="tpButton">
                              {{ report.created_at | formatDate }}
                            </td>

                            <td class="tpButton">
                              {{ report.score }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <!-- leads-table-area  -->

                    <div class="actions-btns" v-if="report.lenght > 0">
                      <ul>
                        <li>
                          <a class="preview-btnn" @click="previewReport"
                            >Preview</a
                          >
                        </li>

                        <li><a @click="deleteReport">Delete</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Dealership -->
          </div>
        </div>
      </div>
    </div>
    <!-- desking-box -->

    <!-- desking-box -->
    <div class="desking-box">
      <div class="note-area">
        <div class="accordion" id="accordionExample5">
          <div class="card">
            <div class="card-header" id="headingOne5">
              <div class="mb-0">
                <button
                  class="btn btn-link"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseOne5"
                  aria-expanded="true"
                  aria-controls="collapseOne5"
                >
                  <div class="detais-title">
                    <h3>
                      <img
                        class="img-fluid arrow-down"
                        src="../../assets/img/arrow-down.svg"
                        alt=""
                      />

                      Deal Summary
                    </h3>
                  </div>
                </button>
              </div>
            </div>

            <div
              id="collapseOne5"
              class="collapse show"
              aria-labelledby="headingOne5"
              data-parent="#accordionExample5"
            >
              <div class="card-body">
                <div class="finece-option">
                  <form>
                    <div class="form-group" v-if="deal_type">
                      <label for="inputEmail4">Sales Price</label>

                      <input
                        type="text"
                        v-model="cash_selling_price"
                        class="form-control"
                      />
                    </div>

                    <div class="form-group" v-if="lease">
                      <label for="inputEmail4">Sales Price</label>

                      <input
                        type="text"
                        v-model="lease_selling_price"
                        class="form-control"
                      />
                    </div>

                    <div class="form-group" v-if="finance">
                      <label for="inputEmail4">Sales Price</label>

                      <input
                        type="text"
                        v-model="selling_price"
                        class="form-control"
                      />
                    </div>

                    <div class="form-group" v-if="finance">
                      <label for="inputEmail4">Net Trade</label>

                      <input
                        type="text"
                        v-model="desking.l_finance_net_trade"
                        class="form-control"
                      />
                    </div>

                    <div class="form-group" v-if="lease">
                      <label for="inputEmail4">Net Trade</label>

                      <input
                        type="text"
                        v-model="desking.l_lease_net_Trade"
                        class="form-control"
                      />
                    </div>

                    <div class="form-group" v-if="deal_type">
                      <label for="inputEmail4">Net Trade</label>

                      <input
                        type="text"
                        v-model="desking.l_cash_net_trade"
                        class="form-control"
                      />
                    </div>
                    <div class="form-group" v-if="finance">
                      <label for="inputEmail4">Rebates</label>

                      <input
                        type="text"
                        v-model="desking.l_finannce_rebate"
                        class="form-control"
                      />
                    </div>

                    <div class="form-group" v-if="lease">
                      <label for="inputEmail4">Rebates</label>

                      <input
                        type="text"
                        v-model="desking.l_lease_rebate"
                        class="form-control"
                      />
                    </div>

                    <div class="form-group" v-if="deal_type">
                      <label for="inputEmail4">Rebates</label>

                      <input
                        type="text"
                        v-model="desking.l_cash_rebate"
                        class="form-control"
                      />
                    </div>
                    <!-- 
                    <div class="form-group">
                      <label for="inputEmail4">Dealer Cash</label>

                      <input type="text" class="form-control" />
                    </div> -->

                    <!-- <div class="form-group">
                      <label for="inputEmail4">Supplier Credit</label>

                      <input type="text" class="form-control" />
                    </div> -->

                    <div class="form-group">
                      <label for="inputEmail4">Fees</label>

                      <input
                        type="text"
                        class="form-control"
                        v-model="sum_fee"
                      />
                    </div>

                    <div class="form-group">
                      <label for="inputEmail4">Add-Ons</label>

                      <input
                        type="text"
                        v-model="calculateaddon"
                        class="form-control"
                      />
                    </div>

                    <div class="form-group" v-if="lease || deal_type">
                      <label for="inputEmail4">Front Gross</label>

                      <input
                        type="text"
                        class="form-control"
                        placeholder="$0.00"
                        v-model="frontgross"
                      />

                      <img
                        class="img-fluid per-sericon"
                        src="assets/img/search.svg"
                        alt=""
                      />
                    </div>

                    <div class="form-group" v-if="lease || deal_type">
                      <label for="inputEmail4">Back Gross</label>

                      <input
                        type="text"
                        class="form-control"
                        placeholder="$0.00"
                        v-model="backgross"
                      />

                      <img
                        class="img-fluid per-sericon"
                        src="assets/img/search.svg"
                        alt=""
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- desking-box -->
    <div
      class="modal fade"
      id="rollpaymentmodal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Roll payment</h5>
            <a class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>
          <div class="modal-body">
            <div>
              <div class="add-member add-lead">
                <div class="form-group">
                  <div class="row" style="margin-bottom: 32px">
                    <div class="col-md-6">
                      <label>Currently Monthly Payment</label>
                      <p class="payment">
                        ${{ lease_downpayment1 | roundoff }}/ mo
                      </p>
                    </div>

                    <div class="col-md-6">
                      <label>Roll Payment To</label>
                      <vue-numeric
                        currency="$"
                        separator=","
                        @input="rollPaymentCalculation"
                        v-bind:precision="2"
                        class="form-control pull-right"
                        v-model="desking.monthlypayment"
                      ></vue-numeric>
                    </div>
                  </div>

                  <div class="row" style="margin-bottom: 32px">
                    <div class="col-md-12">
                      <label>Roll Into</label>
                      <select
                        class="form-control"
                        @change="rollPaymentCalculation"
                        v-model="desking.rollinto"
                      >
                        <option value="cashdown">Cash Down</option>
                        <option value="selling price">Selling Price</option>
                      </select>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <label>Previous Cash Down</label>
                      <p class="price">$ {{ lease_10percent }}</p>
                    </div>
                    <div class="col-md-6">
                      <label>New Monthly Payment</label>
                      <p class="price">$ {{ this.desking.monthlypayment }}</p>
                    </div>

                    <div class="col-md-6" style="margin-top: 32px">
                      <label>Ammount Adjusted</label>
                      <vue-numeric
                        currency="$"
                        separator=","
                        @input="rollPaymentCalculation"
                        v-bind:precision="2"
                        class="form-control pull-right"
                        v-model="desking.ammountadjusted"
                      ></vue-numeric>
                    </div>

                    <div class="col-md-6" style="margin-top: 32px">
                      <label>{{ label }}</label>
                      <vue-numeric
                        currency="$"
                        separator=","
                        v-bind:precision="2"
                        @input="rollPaymentCalculation"
                        class="form-control pull-right"
                        v-model="desking.newcashdown"
                      ></vue-numeric>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <a
              class="add-btn"
              data-dismiss="modal"
              aria-label="Close"
              style="padding-right: 25px; padding-left: 25px; cursor: pointer"
            >
              Cancel
            </a>
            <button @click="addRollPayment" class="rem-btn">Accept</button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="addTradeModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="Add Trade"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Add Trade</h5>

            <a class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>

          <form v-on:submit.prevent="addTrade">
            <div class="modal-body">
              <div class="add-member add-trades">
                <div class="form-group row mb-4">
                  <label class="col-sm-12 col-form-label">VIN</label>

                  <div class="col-sm-8">
                    <input
                      type="text"
                      class="form-control"
                      v-model="trade_.vin"
                    />
                  </div>

                  <div class="col-sm-4">
                    <a
                      v-on:click.stop.prevent="vinDecode('trade')"
                      :disabled="btnLoader"
                      href="#"
                      class="rem-btn"
                      style="width: 100%"
                    >
                      <span v-if="btnLoader" style="width: 100%"
                        ><span class="spinner-grow spinner-grow-sm"></span>

                        Wait...</span
                      ><span
                        style="width: 100%; text-align: center"
                        v-if="!btnLoader"
                      >
                        Decode</span
                      >
                    </a>
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-md-4">
                    <label for="t_years">Years <span>*</span></label>

                    <input
                      type="text"
                      class="form-control"
                      id="t_years"
                      v-model="trade_.years"
                    />
                  </div>

                  <div class="form-group col-md-4">
                    <label>Make <span>*</span></label>

                    <input
                      type="text"
                      class="form-control"
                      v-model="trade_.make"
                    />
                  </div>

                  <div class="form-group col-md-4">
                    <label>Model</label>

                    <input
                      type="text"
                      class="form-control"
                      v-model="trade_.model"
                    />
                  </div>

                  <div class="form-group col-md-4">
                    <label>Trim / Style</label>

                    <input
                      type="text"
                      class="form-control"
                      v-model="trade_.style"
                    />
                  </div>

                  <div class="form-group col-md-4">
                    <label>Color</label>

                    <input
                      type="text"
                      class="form-control"
                      v-model="trade_.color"
                    />
                  </div>

                  <div class="form-group col-md-4">
                    <label>Mileage</label>

                    <input
                      type="text"
                      class="form-control"
                      v-model="trade_.mileage"
                    />
                  </div>

                  <div class="form-group col-md-4">
                    <label>ACV</label>

                    <input
                      type="text"
                      class="form-control"
                      v-model="trade_.acv"
                    />
                  </div>

                  <div class="form-group col-md-4">
                    <label>Allowance</label>

                    <input
                      type="text"
                      class="form-control"
                      v-model="trade_.allowance"
                    />
                  </div>
                </div>

                <div class="form-group">
                  <label>Notes</label>

                  <textarea
                    class="form-control"
                    rows="2"
                    v-model="trade_.notes"
                  ></textarea>
                </div>

                <div class="form-group">
                  <label>Payoff</label>

                  <input
                    type="text"
                    class="form-control"
                    v-model="trade_.payoff"
                  />
                </div>
              </div>
            </div>

            <div class="modal-footer">
              <a
                href="#"
                data-dismiss="modal"
                aria-label="Close"
                class="add-btn"
                >Cancel</a
              >

              <button type="submit" class="rem-btn">Save trade</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="trade_vehicleDetailModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="Add Trade"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Update Trade</h5>

            <a class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>

          <form v-on:submit.prevent="updateTrade">
            <div class="modal-body">
              <div class="add-member add-trades">
                <div class="form-row">
                  <div class="form-group col-md-4">
                    <label for="ut_years">Years <span>*</span></label>

                    <input
                      type="text"
                      class="form-control"
                      id="ut_years"
                      v-model="trade_.years"
                    />
                  </div>

                  <div class="form-group col-md-4">
                    <label>Make <span>*</span></label>

                    <input
                      type="text"
                      class="form-control"
                      v-model="trade_.make"
                    />
                  </div>

                  <div class="form-group col-md-4">
                    <label>Model</label>

                    <input
                      type="text"
                      class="form-control"
                      v-model="trade_.model"
                    />
                  </div>

                  <div class="form-group col-md-4">
                    <label>Trim / Style</label>

                    <input
                      type="text"
                      class="form-control"
                      v-model="trade_.style"
                    />
                  </div>

                  <div class="form-group col-md-4">
                    <label>Color</label>

                    <input
                      type="text"
                      class="form-control"
                      v-model="trade_.color"
                    />
                  </div>

                  <div class="form-group col-md-4">
                    <label>VIN</label>

                    <input
                      type="text"
                      class="form-control"
                      v-model="trade_.vin"
                    />
                  </div>

                  <div class="form-group col-md-4">
                    <label>Mileage</label>

                    <input
                      type="text"
                      class="form-control"
                      v-model="trade_.mileage"
                    />
                  </div>

                  <div class="form-group col-md-4">
                    <label>ACV</label>

                    <input
                      type="text"
                      class="form-control"
                      v-model="trade_.acv"
                    />
                  </div>

                  <div class="form-group col-md-4">
                    <label>Allowance</label>

                    <input
                      type="text"
                      class="form-control"
                      v-model="trade_.allowance"
                    />
                  </div>
                </div>

                <div class="form-group">
                  <label>Notes</label>

                  <textarea
                    class="form-control"
                    rows="2"
                    v-model="trade_.notes"
                  ></textarea>
                </div>

                <div class="form-group">
                  <label>Payoff</label>

                  <input
                    type="text"
                    class="form-control"
                    v-model="trade_.payoff"
                  />
                </div>
              </div>
            </div>

            <div class="modal-footer">
              <a @click="deleteTrade(trade_id)" class="rem-btn">Delete</a>

              <a
                href="#"
                data-dismiss="modal"
                aria-label="Close"
                class="add-btn"
                >Cancel</a
              >

              <button type="submit" class="rem-btn">Update trade</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="previewReport"
      tabindex="-1"
      role="dialog"
      aria-labelledby="Add Trade"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Preview Report</h5>

            <a class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>

          <div class="modal-body">
            <div v-html="html"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="result">
      <div
        class="modal fade"
        id="previewModal"
        ref="testHtml"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content preview-popup">
            <div class="modal-header">
              <img
                class="print"
                src="../../assets/img/print.svg"
                style="position: relative; left: 86%; top: 1px; cursor: pointer"
                @click="print"
              />
              <a data-dismiss="modal" aria-label="Close" class="close"
                ><span aria-hidden="true"
                  ><img
                    src="../../assets/img/23. Closex.svg"
                    alt=""
                    class="img-fluid" /></span
              ></a>
            </div>
            <div class="modal-body">
              <div class="preview-option tablepopup">
                <table class="tables">
                  <tbody>
                    <tr>
                      <td>
                        <div class="pre-name">
                          <h2>{{ customerFullname }}</h2>

                          <p>{{ LDetail.c_street }}</p>
                        </div>
                      </td>
                      <td>
                        <div class="pre-date">
                          <h5>{{ LDetail.l_created | deskingdateformate }}</h5>
                          <p>Source: {{ LDetail.ls_name }}</p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table class="tables">
                  <tbody>
                    <tr>
                      <td>
                        <div class="phn-person">
                          <p>
                            <img
                              class="img-fluid"
                              src="assets/img/calls.svg"
                              alt=""
                            />
                            P: {{ LDetail.c_phone }}
                          </p>
                          <p>
                            <img
                              class="img-fluid"
                              src="assets/img/telephn.svg"
                              alt=""
                            />
                            W:
                          </p>
                        </div>
                      </td>
                      <td>
                        <div class="phn-person">
                          <p>Salesperson</p>
                          <h5>{{ LDetail.assigned_user_str }}</h5>
                        </div>
                      </td>
                      <td>
                        <div class="phn-person">
                          <p>MGR</p>
                          <h5>{{ dealer.dl_name }}</h5>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table class="tables">
                  <tbody>
                    <tr>
                      <td>
                        <div class="pre-milles">
                          <ul>
                            <li>
                              <a href="#"
                                >Mileage: <span>{{ voi.v_mileage }} mi</span></a
                              >
                            </li>
                            <li>
                              <a href="#"
                                >VIN:: <span>{{ voi.v_vin }}</span></a
                              >
                            </li>
                            <li>
                              <a href="#">UC NO:: <span>558222</span></a>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table class="tables">
                  <tbody>
                    <tr>
                      <td>
                        <div class="left-side">
                          <div class="stock-no">
                            <p>Stock NO.</p>
                            <input
                              type="text"
                              style="width: 100px"
                              class="form-control"
                              v-model="voi.v_stock_no"
                            />
                            <label class="containerw">
                              <input type="checkbox" :checked="finance" />

                              <span class="checkmark"></span>

                              Finance
                            </label>
                            <label class="containerw">
                              <input type="checkbox" :checked="lease" />

                              <span class="checkmark"></span>

                              Lease
                            </label>
                            <label class="containerw">
                              <input type="checkbox" :checked="deal_type" />

                              <span class="checkmark"></span>

                              Cash
                            </label>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="right-side">
                          <div class="stock-no">
                            <h6>Trade-In-Information</h6>
                            <form>
                              <div class="rows">
                                <div class="cols">
                                  <label>Year</label>
                                  <input
                                    type="text"
                                    v-model="voi.v_year"
                                    class="form-controls"
                                  />
                                </div>
                                <div class="cols">
                                  <label>Make</label>
                                  <input
                                    type="text"
                                    v-model="voi.v_make"
                                    class="form-controls"
                                  />
                                </div>
                                <div class="cols">
                                  <label>Cyl</label>
                                  <input
                                    type="text"
                                    width="80"
                                    class="form-controls"
                                  />
                                </div>
                                <div class="cols">
                                  <label>Model</label>
                                  <input
                                    type="text"
                                    v-model="voi.v_model"
                                    class="form-controls"
                                  />
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <div class="pre-title">
                          <h4>Fee</h4>
                        </div>
                      </th>
                      <th>
                        <div class="pre-title">
                          <h4>Price</h4>
                        </div>
                      </th>
                    </tr>

                    <tr>
                      <th>
                        <div class="left-side tradein">
                          <div class="">
                            <div class="table-responsive-md">
                              <table
                                class="table"
                                style="width: 70%"
                                v-if="checkfee.length > 0"
                              >
                                <tbody>
                                  <tr v-for="fee in checkfee" :key="fee.id">
                                    <td>
                                      {{ fee.name }}
                                    </td>
                                    <td>${{ fee.ammount }}</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <span style="font-weight: 800"
                                        >Total Fee</span
                                      >
                                    </td>
                                    <td>
                                      <span style="font-weight: 800"
                                        >${{ this.sum_fee }}</span
                                      >
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <p
                                v-else
                                style="text-align: center; font-weight: 800"
                              >
                                No fee selected
                              </p>
                            </div>
                          </div>
                        </div>
                      </th>
                      <th>
                        <div class="right-side">
                          <div class="pre-form">
                            <form>
                              <div class="form-groups rows">
                                <label for="staticEmail" class="width25"
                                  >Original</label
                                >
                                <div class="width75" v-if="finance">
                                  <vue-numeric
                                    currency="$"
                                    v-model="selling_price"
                                    class="form-controls"
                                    separator=","
                                    v-bind:precision="2"
                                  ></vue-numeric>
                                </div>
                                <div class="width75" v-if="lease">
                                  <vue-numeric
                                    currency="$"
                                    v-model="lease_selling_price"
                                    class="form-controls"
                                    separator=","
                                    v-bind:precision="2"
                                  ></vue-numeric>
                                </div>
                                <div class="width75" v-if="deal_type">
                                  <vue-numeric
                                    currency="$"
                                    v-model="cash_selling_price"
                                    class="form-controls"
                                    separator=","
                                    v-bind:precision="2"
                                  ></vue-numeric>
                                </div>
                              </div>
                              <div class="form-groups rows">
                                <label for="staticEmail" class="width25"
                                  >Rebate</label
                                >
                                <div class="width75" v-if="finance">
                                  <vue-numeric
                                    currency="$"
                                    v-model="desking.l_finannce_rebate"
                                    class="form-controls"
                                    separator=","
                                    v-bind:precision="2"
                                  ></vue-numeric>
                                </div>
                                <div class="width75" v-if="lease">
                                  <vue-numeric
                                    currency="$"
                                    v-model="desking.l_lease_rebate"
                                    class="form-controls"
                                    separator=","
                                    v-bind:precision="2"
                                  ></vue-numeric>
                                </div>
                                <div class="width75" v-if="deal_type">
                                  <vue-numeric
                                    currency="$"
                                    v-model="desking.l_cash_rebate"
                                    class="form-controls"
                                    separator=","
                                    v-bind:precision="2"
                                  ></vue-numeric>
                                </div>
                              </div>
                              <div class="form-groups rows">
                                <label for="staticEmail" class="width25"
                                  >Trade In</label
                                >
                                <div class="width75" v-if="finance">
                                  <vue-numeric
                                    currency="$"
                                    v-model="desking.l_finance_net_trade"
                                    class="form-controls"
                                    separator=","
                                    v-bind:precision="2"
                                  ></vue-numeric>
                                </div>
                                <div class="width75" v-if="deal_type">
                                  <vue-numeric
                                    currency="$"
                                    v-model="desking.l_cash_net_trade"
                                    class="form-controls"
                                    separator=","
                                    v-bind:precision="2"
                                  ></vue-numeric>
                                </div>
                                <div class="width75" v-if="lease">
                                  <vue-numeric
                                    currency="$"
                                    v-model="desking.l_lease_net_Trade"
                                    class="form-controls"
                                    separator=","
                                    v-bind:precision="2"
                                  ></vue-numeric>
                                </div>
                              </div>
                              <div class="form-groups rows">
                                <label for="staticEmail" class="width25"
                                  >Final</label
                                >

                                <div class="width75" v-if="finance">
                                  <vue-numeric
                                    currency="$"
                                    v-model="financefinal"
                                    class="form-controls"
                                    separator=","
                                    v-bind:precision="2"
                                  ></vue-numeric>
                                </div>
                                <div class="width75" v-if="lease">
                                  <vue-numeric
                                    currency="$"
                                    v-model="leasefinal"
                                    class="form-controls"
                                    separator=","
                                    v-bind:precision="2"
                                  ></vue-numeric>
                                </div>
                                <div class="width75" v-if="cash">
                                  <vue-numeric
                                    currency="$"
                                    v-model="cashfinal"
                                    class="form-controls"
                                    separator=","
                                    v-bind:precision="2"
                                  ></vue-numeric>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </th>
                    </tr>
                    <tr>
                      <th>
                        <div v-if="finance | lease" class="pre-title">
                          <h4>Down payment</h4>
                        </div>
                      </th>
                      <th>
                        <div v-if="finance | lease" class="pre-title">
                          <h4>Monthly payment</h4>
                        </div>
                      </th>
                    </tr>
                    <tr>
                      <th v-if="finance">
                        <div class="left-side">
                          <div class="pre-form">
                            <form>
                              <div class="form-groups rows">
                                <label for="staticEmail" class="width25"
                                  >Down 1</label
                                >
                                <div class="width75">
                                  <vue-numeric
                                    currency="$"
                                    v-model="finance_10percent"
                                    class="form-controls"
                                    separator=","
                                    v-bind:precision="2"
                                  ></vue-numeric>
                                </div>
                              </div>
                              <div class="form-groups rows">
                                <label for="staticEmail" class="width25"
                                  >Down 2</label
                                >
                                <div class="width75">
                                  <vue-numeric
                                    currency="$"
                                    v-model="finance_10percent"
                                    class="form-controls"
                                    separator=","
                                    v-bind:precision="2"
                                  ></vue-numeric>
                                </div>
                              </div>
                              <div class="form-groups rows">
                                <label for="staticEmail" class="width25"
                                  >Down 3</label
                                >
                                <div class="width75">
                                  <vue-numeric
                                    currency="$"
                                    v-model="finance_10percent"
                                    class="form-controls"
                                    separator=","
                                    v-bind:precision="2"
                                  ></vue-numeric>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </th>
                      <th v-if="finance">
                        <div class="right-side">
                          <div class="pre-form">
                            <form>
                              <div class="form-groups rows">
                                <label class="width25">Payment 1</label>
                                <div class="width75">
                                  <vue-numeric
                                    currency="$"
                                    v-model="downpayment1"
                                    class="form-controls"
                                    separator=","
                                    v-bind:precision="2"
                                  ></vue-numeric>
                                </div>
                              </div>
                              <div class="form-groups rows">
                                <label for="staticEmail" class="width25"
                                  >Payment 2</label
                                >
                                <div class="width75">
                                  <vue-numeric
                                    currency="$"
                                    v-model="downpayment2"
                                    class="form-controls"
                                    separator=","
                                    v-bind:precision="2"
                                  ></vue-numeric>
                                </div>
                              </div>
                              <div class="form-groups rows">
                                <label for="staticEmail" class="width25"
                                  >Payment 3</label
                                >
                                <div class="width75">
                                  <vue-numeric
                                    currency="$"
                                    v-model="downpayment3"
                                    class="form-controls"
                                    separator=","
                                    v-bind:precision="2"
                                  ></vue-numeric>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </th>
                      <th v-if="lease">
                        <div class="left-side">
                          <div class="pre-form">
                            <form>
                              <div class="form-groups rows">
                                <label for="staticEmail" class="width25"
                                  >Down 1</label
                                >
                                <div class="width75">
                                  <vue-numeric
                                    currency="$"
                                    v-model="lease_10percent"
                                    class="form-controls"
                                    separator=","
                                    v-bind:precision="2"
                                  ></vue-numeric>
                                </div>
                              </div>
                              <div class="form-groups rows">
                                <label for="staticEmail" class="width25"
                                  >Down 2</label
                                >
                                <div class="width75">
                                  <vue-numeric
                                    currency="$"
                                    v-model="lease_15percent"
                                    class="form-controls"
                                    separator=","
                                    v-bind:precision="2"
                                  ></vue-numeric>
                                </div>
                              </div>
                              <div class="form-groups rows">
                                <label for="staticEmail" class="width25"
                                  >Down 3</label
                                >
                                <div class="width75">
                                  <vue-numeric
                                    currency="$"
                                    v-model="lease_20percent"
                                    class="form-controls"
                                    separator=","
                                    v-bind:precision="2"
                                  ></vue-numeric>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </th>
                      <th v-if="lease">
                        <div class="right-side">
                          <div class="pre-form">
                            <form>
                              <div class="form-groups rows">
                                <label class="width25">Payment 1</label>
                                <div class="width75">
                                  <vue-numeric
                                    currency="$"
                                    v-model="lease_downpayment1"
                                    class="form-controls"
                                    separator=","
                                    v-bind:precision="2"
                                  ></vue-numeric>
                                </div>
                              </div>
                              <div class="form-groups rows">
                                <label for="staticEmail" class="width25"
                                  >Payment 2</label
                                >
                                <div class="width75">
                                  <vue-numeric
                                    currency="$"
                                    v-model="lease_15percent"
                                    class="form-controls"
                                    separator=","
                                    v-bind:precision="2"
                                  ></vue-numeric>
                                </div>
                              </div>
                              <div class="form-groups rows">
                                <label for="staticEmail" class="width25"
                                  >Payment 3</label
                                >
                                <div class="width75">
                                  <vue-numeric
                                    currency="$"
                                    v-model="lease_20percent"
                                    class="form-controls"
                                    separator=","
                                    v-bind:precision="2"
                                  ></vue-numeric>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </th>
                    </tr>
                  </tbody>
                </table>

                <table class="tables noboder">
                  <tbody>
                    <tr>
                      <td>
                        <div class="pre-form">
                          <div class="form-groups">
                            <label for="exampleInputEmail1">Signature </label>
                            <!-- <input
                              type="text"
                              class="form-controls signature"
                            /> -->

                            <vueSignature
                              id="signatuteimg"
                              ref="signature"
                              :sigOption="option"
                              :w="'633px'"
                              :h="'100px'"
                              :defaultUrl="dataUrl"
                            ></vueSignature>

                            <img
                              src=""
                              style="width: 633px; height: 100px; display: none"
                              id="signature"
                            />
                            <!-- <button class="rem-btn" @click="save">Save</button>
                            <button class="rem-btn" @click="clear">
                              Clear
                            </button> -->
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="result">
      <div
        class="modal fade"
        id="vehicleDetailModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="vehicleDetailModalLabel"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div class="modal-content">
            <a
              class="delas-colse close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>

            <div class="modal-body Intersets-modal">
              <div class="row">
                <div class="col-lg-7">
                  <div class="exzoom" id="exzoom">
                    <div class="exzoom_img_box">
                      <ul class="exzoom_img_ul">
                        <li>
                          <img
                            class="img-fluid car"
                            :src="v_detail.v_image"
                            alt=""
                          />
                        </li>
                      </ul>
                    </div>

                    <!-- <div class="exzoom_nav"></div>
    
                                                                    <p class="exzoom_btn">
    
                                                                        <a href="javascript:void(0);" class="exzoom_prev_btn"> </a>
    
                                                                        <a href="javascript:void(0);" class="exzoom_next_btn"> > </a>
    
                                                                    </p> -->
                  </div>

                  <!-- <div class="notes-con">
    
                                                                    <h3>Note</h3>
    
                                                                    <input type="text" class="form-control" placeholder="Customer called and setup  appointments" />
    
                                                                </div>
    
                                                                <div class="notes-con">
    
                                                                    <h3>Notes</h3>
    
                                                                    <input type="text" class="form-control" placeholder="This car was diver" />
    
                                                                </div> -->
                </div>

                <div class="col-lg-5 pl-0">
                  <div class="details-modal">
                    <table class="table">
                      <tbody>
                        <tr>
                          <td colspan="2">
                            <h5 style="color: #000">
                              {{ v_detail.v_year }} {{ v_detail.v_make }}
                              {{ v_detail.v_model }}
                            </h5>
                          </td>
                        </tr>

                        <tr>
                          <td class="td">Stock#</td>

                          <td>{{ v_detail.v_stock_no }}</td>
                        </tr>

                        <tr>
                          <td class="td">Mileage</td>

                          <td>{{ v_detail.v_mileage }}</td>
                        </tr>

                        <tr>
                          <td class="td">Color</td>

                          <td>
                            <!--<span class="black"></span>-->{{
                              v_detail.v_ext_color
                            }}
                          </td>
                        </tr>

                        <tr>
                          <td class="td">Ext</td>

                          <td>{{ v_detail.v_ext_color }}</td>
                        </tr>

                        <tr>
                          <td class="td">Interior</td>

                          <td>{{ v_detail.v_int_color }}</td>
                        </tr>

                        <tr>
                          <td class="td">Engine</td>

                          <td>{{ v_detail.v_engine }}</td>
                        </tr>

                        <tr>
                          <td class="td">Last Price</td>

                          <td>${{ v_detail.v_price }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <!-- People Are Intersted -->

                  <!-- <div class="People-Intersted">
    
                                                                    <h3>People Are Intersted</h3>
    
                                                                    <div class="inter-people">
    
                                                                        <ul>
    
                                                                            <li>
    
                                                                                <a href="#"><img class="img-fluid" src="assets/img/inrerest3.svg" alt="" /></a>
    
                                                                            </li>
    
                                                                            <li>
    
                                                                                <a href="#"><img class="img-fluid" src="assets/img/inrerest2.svg" alt="" /></a>
    
                                                                            </li>
    
                                                                            <li>
    
                                                                                <a href="#"><img class="img-fluid" src="assets/img/inrerest1.svg" alt="" /></a>
    
                                                                            </li>
    
                                                                        </ul>
    
                                                                        <p>20 People <a href="#" data-toggle="modal" data-target="#exampleModal22">Show more</a></p>
    
                                                                    </div>
    
                                                                </div> -->

                  <!-- People Are Intersted -->
                </div>

                <div class="col-12">
                  <div class="notes-con" v-if="v_detail.v_description">
                    <h3>Description</h3>

                    <p v-html="v_detail.v_description"></p>
                  </div>
                </div>

                <div class="col-12 text-center mt-3">
                  <button
                    class="rem-btn"
                    style="display: initial"
                    v-on:click="updateVOI(v_detail)"
                  >
                    Select
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade"
      id="customeService"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Add Item</h5>
            <a class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>

          <div class="modal-body">
            <div class="add-member">
              <label>Item Name</label>
              <input type="text" v-model="service" class="form-control" />
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="rem-btn" v-on:click="selectService()">
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="leavemodal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Attention</h5>
            <a class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true"
                ><img
                  class="img-fluid"
                  src="../../assets/img/23. Closex.svg"
                  alt=""
              /></span>
            </a>
          </div>

          <div class="modal-body">
            <p style="margin-bottom: 20px">Do you want to save the changes?</p>
          </div>
          <div class="modal-footer">
            <button
              @click="yes"
              :disabled="btnLoader"
              type="button"
              class="rem-btn"
            >
              <span v-if="btnLoader"
                ><span class="spinner-grow spinner-grow-sm"></span>
                Loading...</span
              >
              <span v-if="!btnLoader"> Yes</span>
            </button>
            <a href="#" @click="no" class="add-btn"> No</a>
          </div>
        </div>
      </div>
    </div>
    <!-- Add New Form Modal -->
    <div
      class="modal fade"
      id="exampleModal7"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel7"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel7">Add New Form</h5>
            <a class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">
                <img
                  class="img-fluid"
                  src="../../assets/img/23. Closex.svg"
                  alt=""
                />
              </span>
            </a>
          </div>
          <div class="modal-body">
            <div class="add-member add-lead">
              <form class="test" method="post">
                <div class="form-row">
                  <div class="form-group col-md-12">
                    <label for="inputEmail4">Form Title</label>
                    <input
                      type="text"
                      v-model="form.title"
                      class="form-control"
                      id="inputEmail4"
                      placeholder="Form Title"
                    />
                    <span
                      style="color: red"
                      v-if="allerros.title"
                      :class="['label label-danger']"
                      >{{ allerros.title[0] }}</span
                    >
                  </div>
                </div>

                <div class="user-img printpdf">
                  <img
                    class="img-fluid"
                    src="../../assets/img/upload.svg"
                    alt=""
                  />
                  <p>Drag and drop here</p>
                  <p>or</p>
                  <div class="button-wrapper">
                    <span class="label">Browse</span>

                    <input
                      type="file"
                      name="file"
                      id="upload"
                      class="upload-box"
                      placeholder="Upload File"
                      v-on:change="onFileChange"
                    />
                  </div>
                </div>
              </form>
            </div>
            <div class="pdf-list">
              <span
                style="color: red"
                v-if="allerros.file"
                :class="['label label-danger']"
                >{{ allerros.file[0] }}</span
              ><br />
              <ul v-if="form.file">
                <li>
                  <a href="#">
                    <img
                      class="img-fluid"
                      src="../../assets/img/pdf2.svg"
                      alt=""
                    />{{ form.file }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="modal-footer">
            <a href="#" class="add-btn" data-dismiss="modal" aria-label="Close">
              Cancel
            </a>
            <button
              @click="addNewForm"
              :disabled="btnLoader"
              type="button"
              class="rem-btn"
            >
              <span v-if="btnLoader"
                ><span class="spinner-grow spinner-grow-sm"></span>
                Loading...</span
              >
              <span v-if="!btnLoader"> Add</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Add New Form Modal -->
  </div>
</template>

<script>
import axios from "axios";
import Autocomplete from "vuejs-auto-complete";
import { Carousel, Slide } from "vue-carousel";
import $ from "jquery";
import "datatables.net";
import "datatables.net-responsive";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";
import printJS from "print-js";
import { PDFDocument } from "pdf-lib";
import VueNumeric from "vue-numeric";
import vueSignature from "vue-signature";
import Swal from "sweetalert2";
// import VueDaval from "vue-daval";
export default {
  name: "Desking",
  beforeCreate: function () {
    var auth = this.$storage.get("auth");
    if (!auth) {
      this.$router.push("/login");
    } else {
      if (!auth.token) {
        this.$router.push("/login");
      }
    }
  },
  components: {
    Carousel,
    Slide,
    Autocomplete,
    VueNumeric,
    vueSignature,
  },
  data() {
    return {
      base64signature: "",
      signatuteimg: true,
      option: {
        penColor: "rgb(0, 0, 0)",
        backgroundColor: "rgb(255,255,255)",
      },
      disabled: false,
      dataUrl: "",
      checkform: [],
      dmsLoader: false,
      allerros: [],
      form: {
        title: "",
        file: "",
      },
      forms: [],
      files: "",
      route: "",
      print_src: "",
      finance_tax_value: 0,
      lease_tax_value: 0,
      cash_tax_value: 0,
      cash: "",
      leaseFinalAmmount: "",
      label: "",
      service: "",
      counter: "",
      checkfee: [],
      lenderdata: [],
      lender: {},
      programMilesPlaceholder: 0,
      MainCalculationFinance_placeholder: 0,
      aftermarket_placeholder: 0,
      selling_price_placeholder: 0,
      gap_placeholder: 0,
      maintenance_placeholder: 0,
      addon_placeholder: 0,
      vsc_placeholder: 0,
      aftermarket: [],
      aftermarketdata: [],
      fees: [],
      maintenance: [],
      gap: [],
      vsc: [],
      lease_terms: 60,
      financec_terms: 60,
      cash_selling_price: "",
      l_lease_apr: 7,
      reportid: "",
      report: [],
      experian_score: "",
      transunion_score: "",
      equifax_score: "",
      html: "",
      bureu: "Experian",
      company: "700 credit",
      client: "Buyer",
      lease_selling_price: "",
      cash_terms: 60,
      finance_10percent: 0,
      finance_15percent: 0,
      finance_20percent: 0,
      lease_20percent: 0,
      lease_15percent: 0,
      lease_10percent: 0,
      lease_downpayment1: 0,
      lease_downpayment2: 0,
      lease_downpayment3: 0,
      desking: {
        l_cash_selling_price: 0,
        deal_type: "finance",
        l_lease_apr: 7,
        downpayment: 1,
        l_cash_rebate: 0,
        l_cash_net_trade: 0,
        l_finannce_rebate: 0,
        l_finance_net_trade: 0,
        l_lease_net_Trade: 0,
        l_lease_rebate: 0,
        l_finance_apr: 7,
        l_finance_amount: 0,
        l_lease_taxrate: 0,
        onePayLeaseplaceholder: 0,
        rollinto: "cashdown",
      },
      l_lease_residual: 0,
      onepaylease: 0,
      maintenance_val: "",
      gap_value: "",
      sum_fee: 0,
      fee: [],
      vsc_value: "",
      salestax: true,
      tax_value: 0,
      l_finance_tax: "",
      selling_price: "",
      termprice: "",
      sellingprice: "",
      deal_type: false,
      finance: true,
      lease: false,
      cash1: "",
      cash2: "",
      cash3: "",
      lcash1: "",
      lcash2: "",
      lcash3: "",
      downpayment1: 0,
      downpayment2: 0,
      downpayment3: 0,
      trade_id: "",
      btnLoader: false,
      trade_: {
        years: "",
        make: "",
        model: "",
        mileage: "",
        color: "",
        style: "",
        acv: "",
      },
      allEmployees: "",
      LDetail: {},
      register_: {},
      t_: {
        prices: 0,
        disc: 0,
        acces: 0,
        fee: 0,
        trade: {},
      },
      v_detail: {},
      voi: {},
      trades: [],
      dealer: {},
      rollpaymet: {},
      money: {
        thousands: ".",
        prefix: "$",
        precision: 2,
        masked: false,
      },
    };
  },
  created() {
    this.getAllEmployees();
    this.getLeadDetail();
    this.getTrades();
    this.getVOI();
    this.getDealer();
    this.getDesking();
    this.getCredit();
    this.getFees();
    this.getVsc();
    this.getMaintenance();
    this.getGap();
    this.getaftermarket();
    this.getforms();
    this.getLender();
    let vm = this;
    this.$root.$on("action", function (action, data) {
      if (action == "leaveit") {
        console.log(data.fullPath);
        vm.route = data.fullPath;
        $("#leavemodal").modal("show");
      }
    });
  },
  mounted() {
    window.onafterprint = function () {
      $("#signature").hide();
      $("#signatuteimg").show();

      console.log("Print Dialog Closed..");
    };
    //   $('.finece-box').click(function() {
    //     $('.finece-box').removeClass("active");
    //     $(this).addClass("active");
    //     if ($(".finece-box").hasClass("active")) {
    //       $(".active  p").addClass('white');
    //         $(".active  h3").addClass('white');
    //     }

    // });

    $(".creadit-tabs tbody tr").click(function () {
      $(".creadit-tabs tbody tr").removeClass("highlights");
      $(this).addClass("highlights");
      $(".actions-btns").addClass("highlights");
    });
    //table
    //$(".display").dataTable();

    $(".display :checkbox").change(function () {
      $(this).parent().parent().toggleClass("selecet-bg");
    });

    $(".display :checkbox").change(function () {
      $(this).parent().parent().parent().toggleClass("selecet-bg");
    });
    $("#report").on("click", ".tpButton", function () {
      var id = $(this).closest("tr").attr("id");
      $("#report tr").removeClass("even highlights");
      $(".row_id_" + id).addClass("even highlights");
      $(".actions-btns").addClass("highlights");

      this.reportid = id;
      localStorage.setItem("id", id);
      console.log(localStorage.getItem("id"));
    });
  },

  computed: {
    IsV2(){
      if(this.$storage.get('auth').user){
        if(this.$storage.get('auth').user.dealer_autolink_version){
          if(this.$storage.get('auth').user.dealer_autolink_version == 'v2'){
            return true;
          }
        }
      }
      return false;
    },
    IsV2TokenExist(){
      if(this.$storage.get('auth').user){
        if(this.$storage.get('auth').user.dealer_autolink_v2_auth_token){
          if(this.$storage.get('auth').user.dealer_autolink_v2_auth_token != ''){
            return true;
          }
        }
      }
      return false;
    },
    V2AuthHeader(){
      if(this.$storage.get('auth').user){
        if(this.$storage.get('auth').user.dealer_autolink_v2_auth_token){
          if(this.$storage.get('auth').user.dealer_autolink_v2_auth_token != ''){
            return {
              'Authorization' : 'Bearer ' + this.$storage.get('auth').user.dealer_autolink_v2_auth_token
            };
          }
        }
      }
      return {};
    },
    finance_amount(){
      //(Selling Price - Cash Down 1 - Net Trade) + (Fees + Taxes)
      return (this.selling_price - this.finance_10percent - this.desking.l_finance_net_trade) + (this.sum_fee+this.finance_tax_value);
    },
    lease_finance_amount(){
      //(Selling Price - Cash Down 1 - Net Trade) + (Fees + Taxes)
      return (this.lease_selling_price - this.lease_10percent - this.desking.l_lease_net_Trade) + (this.sum_fee+this.finance_tax_value);
    },
    backgross() {
      return this.sum_fee + this.calculateaddon;
    },
    frontgross() {
      return this.leaseFinalAmmount - this.backgross;
    },
    estimatedgross() {
      return this.frontgross + this.backgross;
    },
    financefinal() {
      return this.selling_price - this.desking.l_finannce_rebate;
    },
    leasefinal() {
      return this.selling_price - this.desking.l_lease_rebate;
    },
    cashfinal() {
      return this.selling_price - this.desking.l_cash_rebate;
    },
    customerFullname() {
      if (this.LDetail.c_first_name && this.LDetail.c_last_name) {
        return this.LDetail.c_first_name + " " + this.LDetail.c_last_name;
      } else {
        return "";
      }
    },
    calculateaddon() {
      var addon = "";
      addon =
        Number(this.maintenance_placeholder) +
        Number(this.aftermarket_placeholder) +
        Number(this.vsc_placeholder) +
        Number(this.gap_placeholder);
      return addon;
    },
    // calculatesellingprice() {
    //   var sellingpricee = "";
    //   sellingpricee =
    //     this.selling_price +
    //     this.sum_fee +
    //     this.tax_value +
    //     this.calculateaddon -
    //     this.desking.l_finannce_rebate -
    //     this.desking.l_finance_net_trade;
    //   return sellingpricee;
    // },
    // calculateleasesellingprice() {
    //   var sellingpricee = "";
    //   sellingpricee =
    //     this.lease_selling_price +
    //     this.sum_fee +
    //     this.tax_value +
    //     this.calculateaddon -
    //     this.desking.l_lease_rebate -
    //     this.desking.l_lease_net_Trade;
    //   return sellingpricee;
    // },
    // calculatcashsellingprice() {
    //   var sellingpricee = "";
    //   sellingpricee =
    //     this.cash_selling_price +
    //     this.sum_fee +
    //     this.calculateaddon -
    //     this.desking.l_cash_rebate -
    //     this.desking.l_cash_net_trade;
    //   return sellingpricee;
    // },
    calculateTax() {
      var tax = "";
      tax =
        (this.l_finance_tax / 100) *
        (this.selling_price - this.desking.l_finance_net_trade);
      return tax;
    },
    calculateLeaseTax() {
      var tax = "";
      tax =
        (this.l_finance_tax / 100) *
        (this.lease_selling_price - this.desking.l_lease_net_Trade);
      return tax;
    },
    calculateCashTax() {
      var tax = "";
      tax =
        (this.l_finance_tax / 100) *
        (this.lease_selling_price - this.desking.l_cash_net_trade);
      return tax;
    },

    downpaymentone() {
      var downpayment =
        (this.calculatesellingprice - this.finance_10percent) /
        this.financec_terms;
      return downpayment;
    },

    downpaymenttwo() {
      var downpayment =
        (this.calculatesellingprice - this.finance_15percent) /
        this.financec_terms;
      return downpayment;
    },

    downpaymentthree() {
      var downpayment =
        (this.calculatesellingprice - this.finance_20percent) /
        this.financec_terms;
      return downpayment;
    },

    leasedownpaymentone() {
      var leasedoenpayment =
        (this.calculateleasesellingprice - this.lease_10percent) /
        this.MainCalculationFinance;
      return leasedoenpayment;
    },
    leasedownpaymenttwo() {
      var leasedoenpayment =
        (this.calculateleasesellingprice - this.lease_15percent) /
        this.MainCalculationFinance;
      return leasedoenpayment;
    },
    leasedownpaymentthree() {
      var leasedoenpayment =
        (this.calculateleasesellingprice - this.lease_20percent) /
        this.MainCalculationFinance;
      return leasedoenpayment;
    },
    cashone() {
      if (
        this.desking.l_lease_D1 &&
        this.desking.l_lease_selling_price &&
        this.desking.l_lease_terms
      ) {
        var downpayment3 =
          (this.desking.l_lease_selling_price - this.desking.l_lease_D1) /
          this.desking.l_lease_terms;
        return parseFloat(downpayment3).toFixed(2);
      } else {
        return 0;
      }
    },
    cashtwo() {
      if (
        this.desking.l_lease_D2 &&
        this.desking.l_lease_selling_price &&
        this.desking.l_lease_terms
      ) {
        var downpayment3 =
          (this.desking.l_lease_selling_price - this.desking.l_lease_D2) /
          this.desking.l_lease_terms;
        return parseFloat(downpayment3).toFixed(2);
      } else {
        return 0;
      }
    },
    cashthree() {
      if (
        this.desking.l_lease_D3 &&
        this.desking.l_lease_selling_price &&
        this.desking.l_lease_terms
      ) {
        var downpayment3 =
          (this.desking.l_lease_selling_price - this.desking.l_lease_D3) /
          this.desking.l_lease_terms;
        return parseFloat(downpayment3).toFixed(2);
      } else {
        return 0;
      }
    },
  },
  // mixins: [VueDaval],

  methods: {
    save() {
      var _this = this;
      var png = _this.$refs.signature.save();
      console.log(png);
      axios
        .post(
          "update/desking/signature",
          {
            signatute: png,
            lead_id: this.$route.params.id,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((res) => {
          if (res.data.success) {
            this.$toast.open({
              position: "top-right",
              message: res.data.message,
            });
          }
        });
    },
    clear() {
      var _this = this;
      _this.$refs.signature.clear();
    },
    undo() {
      var _this = this;
      _this.$refs.signature.undo();
    },
    // addWaterMark() {
    //   var _this = this;
    //   _this.$refs.signature.addWaterMark({
    //     text: "mark text", // watermark text, > default ''
    //     font: "20px Arial", // mark font, > default '20px sans-serif'
    //     style: "all", // fillText and strokeText,  'all'/'stroke'/'fill', > default 'fill
    //     fillStyle: "red", // fillcolor, > default '#333'
    //     strokeStyle: "blue", // strokecolor, > default '#333'
    //     x: 100, // fill positionX, > default 20
    //     y: 200, // fill positionY, > default 20
    //     sx: 100, // stroke positionX, > default 40
    //     sy: 200, // stroke positionY, > default 40
    //   });
    // },

    // handleDisabled() {
    //   var _this = this;
    //   _this.disabled = !_this.disabled;
    // },
    fromDataURL(url) {
      var _this = this;
      _this.$refs.signature.fromDataURL(url);
    },
    sendtodms() {
      this.dmsLoader = true;
      if (
        this.LDetail.c_first_name == "" ||
        this.LDetail.c_first_name == null
      ) {
        this.dmsLoader = false;
        alert("Customer first name is required");
        return false;
      }
      if (this.LDetail.c_last_name == "" || this.LDetail.c_last_name == null) {
        this.dmsLoader = false;
        alert("Customer last name is required");
        return false;
      }
      if (this.LDetail.c_city == "" || this.LDetail.c_city == null) {
        this.dmsLoader = false;
        alert("city is required");
        return false;
      }
      if (this.LDetail.c_state == "" || this.LDetail.c_state == null) {
        this.dmsLoader = false;
        alert("state is required");
        return false;
      }
      if (this.LDetail.c_street == "" || this.LDetail.c_street == null) {
        this.dmsLoader = false;
        alert("Address is required");
        return false;
      }

      axios
        .get("sendtodms", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
          params: {
            phone: this.LDetail.c_phone,
            c_first_name: this.LDetail.c_first_name.substring(0, 10).toUpperCase(),
            c_last_name: this.LDetail.c_last_name.substring(0, 10).toUpperCase(),
            c_email: this.LDetail.c_email,
            c_dob: this.LDetail.c_dob,
            c_city: this.LDetail.c_city.toUpperCase(),
            c_state: this.LDetail.c_state,
            c_zip: this.LDetail.c_zip,
            c_street: this.LDetail.c_street.toUpperCase(),
            c_id: this.LDetail.c_id,
          },
        })

        .then((res) => {
          if (res.data.status == true) {
            this.dmsLoader = false;
            Swal.fire(res.data.message, "", "success");
          }else{
            this.dmsLoader = false;
            Swal.fire("Error",res.data.message, "error");
          }
        })
        .catch((err) => {
          this.dmsLoader = false;
          console.log(err);
        });
    },
    downloadPdf(url, id) {
      $("#spinner_" + id).show();
      axios({
        url: "downloadpdf",
        method: "GET",
        responseType: "blob",
        params: {
          url: url,
        },
      }).then((response) => {
        $("#spinner_" + id).hide();
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", "file.pdf");
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
    checkAllForms(event) {
      if (event.target.checked) {
        this.forms.forEach((data) => {
          this.checkform.push(data.form);
        });
      } else {
        this.checkform = [];
      }
    },

    downloadFile(data) {
      const blob = new Blob([data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      this.btnLoader = false;
      //window.open(url);
      printJS({
        printable: url,
        type: "pdf",
      });
    },
    async printPDFS() {
      this.btnLoader = true;
      if (this.checkform.length == 0) {
        this.$toast.open({
          position: "top-right",
          message: "Please select form",
          type: "info",
        });
        this.btnLoader = false;
        return false;
      }
      /* Array of pdf urls */
      let pdfsToMerge = this.checkform;

      const mergedPdf = await PDFDocument.create();
      for (const pdfCopyDoc of pdfsToMerge) {
        const pdfBytes = await fetch(pdfCopyDoc).then((res) =>
          res.arrayBuffer()
        );
        //const pdfBytes = fs.readFileSync(pdfCopyDoc);
        const pdf = await PDFDocument.load(pdfBytes);
        const copiedPages = await mergedPdf.copyPages(
          pdf,
          pdf.getPageIndices()
        );
        copiedPages.forEach((page) => {
          mergedPdf.addPage(page);
        });
      }
      const mergedPdfFile = await mergedPdf.save();
      this.downloadFile(mergedPdfFile);
    },

    updatemarketplacee(counter) {
      axios
        .post(
          "update/aftermarket",
          {
            lead_id: this.$route.params.id,
            aftermarket: this.aftermarketdata[counter],
          },
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((res) => {
          if (res.data.success) {
            this.aftermarket_placeholder = res.data.aftermarketprice;
            if (this.desking.deal_type == "finance") {
              this.MainCalculationFinance();
            }
            if (this.desking.deal_type == "cash") {
              this.MainCalculationCash();
            }
            if (this.desking.deal_type == "lease") {
              this.MainCalculationLease();
            }
            console.log(res.data.cost);

            this.service = "";
            this.$toast.open({
              position: "top-right",
              message: res.data.message,
            });
          }
        });
    },
    printall() {
      var pages = [
        "https://dealersgearcrm.s3.us-west-1.amazonaws.com/dev/uploads/1a39e0b752a90fc175d8b57e8b78c530.pdf",
      ];
      for (var i = 0; i < pages.length; i++) {
        var oWindow = window.open(pages[i], "print");
        oWindow.print();
        oWindow.close();
      }

      // axios
      //   .post("/pdf", { pdf: this.checkform })
      //   .then((response) => {
      //     console.log(response);
      //     printJS({
      //       printable: "https://dealersgearcrm.com//tracker-module.pdf",
      //       type: "pdf",
      //       showModal: true,
      //     });
      //   })
      //   .catch((error) => {
      //     this.allerros = error.response.data.errors;
      //     this.btnLoader = false;
      //     console.log(error);
      //   });
    },
    getforms() {
      axios
        .get("get/forms/" + this.$route.params.id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.forms = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onFileChange(e) {
      this.files = e.target.files;
      this.form.file = e.target.files[0].name;
    },
    addNewForm(e) {
      e.preventDefault();
      this.btnLoader = true;
      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: "Bearer " + this.$storage.get("auth").token,
        },
      };
      let formData = new FormData();

      for (const i of Object.keys(this.files)) {
        formData.append("file", this.files[i]);
      }
      formData.append("title", this.form.title);
      formData.append("lead_id", this.$route.params.id);
      axios
        .post("/addnewform", formData, config)
        .then((response) => {
          $("#exampleModal7").modal("hide");
          this.files = "";
          this.form = {
            title: "",
            file: [],
          };
          this.getforms();
          this.allerros = [];
          this.btnLoader = false;
          console.log(response.data.success);
        })
        .catch((error) => {
          this.allerros = error.response.data.errors;
          this.btnLoader = false;
          console.log(error);
        });
    },
    no() {
      window.location.href = this.route;
    },
    yes() {
      this.$store.state.desking = true;
      this.adddesking();
    },
    sumaddon() {
      var sum =
        this.maintenance_placeholder +
        this.aftermarket_placeholder +
        this.vsc_placeholder +
        this.gap_placeholder;
      return sum;
    },
    print() {
      $("#signatuteimg").hide();
      const canvas = document.getElementById("canvas60");
      const img = canvas.toDataURL("image/png");
      this.base64signature = img;
      $("#signature").show();
      $("#signature").attr("src", img);
      const modal = document.getElementById("previewModal");
      const cloned = modal.cloneNode(true);
      let section = document.getElementById("print");

      if (!section) {
        section = document.createElement("div");
        section.id = "print";
        document.body.appendChild(section);
      }

      section.innerHTML = "";
      section.appendChild(cloned);
      // Small delay of 0.5s
      window.moveTo(0, 0);
      window.resizeTo(640, 480);
      setTimeout(() => {
        window.print();
        window.close();
      }, 500);
    },
    updateCustomer() {
      axios
        .post(
          "update_customer",
          {
            c_id: this.LDetail.c_id,
            c_phone: this.LDetail.c_phone,
            c_email: this.LDetail.c_email,
            c_first_name: this.LDetail.c_first_name,
            c_last_name: this.LDetail.c_last_name,
            c_street: this.LDetail.c_street,
            c_city: this.LDetail.c_city,
            c_zip: this.LDetail.c_zip,
            c_address: this.LDetail.c_address,
            c_state: this.LDetail.c_state,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((res) => {
          //console.log(res.data)
          if (res.data.success) {
            if (res.data.permission == false) {
              this.lead_detail.c_is_first_sms = 1;
              this.lead_detail.c_sms_permission = 0;
            }
            this.$toast.open({
              position: "top-right",
              message: "Customer Updated",
              type: "success",
            });
            this.$root.$emit("action", "update_customer_email");
          } else if (res.data.status == false) {
            this.$toast.open({
              position: "top-right",
              message: "user with same phone or email exist",
              type: "info",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    gettax() {
      axios
        .get("get/tax", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.l_finance_tax = res.data.tax;
            this.MainCalculationFinance();
            this.MainCalculationLease();
            this.MainCalculationCash();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    rollPaymentCalculation() {
      var newMonthlyPayment = this.desking.monthlypayment;

      // var previousMonthlyPayment = this.lease_downpayment1;

      var rollInto = this.desking.rollinto;
      var finalAmount =
        this.lease_selling_price +
        this.sum_fee +
        this.calculateaddon -
        (this.desking.l_lease_net_Trade + this.desking.l_lease_rebate);
      this.lease_selling_price_placeholder = finalAmount;

      // var payment1 = finalAmount - this.lease_10percent;
      var adjustedAmount = newMonthlyPayment * this.lease_terms;
      if (rollInto == "cashdown") {
        var NewCashDown = this.lease_selling_price - adjustedAmount;
        this.desking.newcashdown = NewCashDown;
        this.desking.ammountadjusted = NewCashDown - this.lease_10percent;
        this.label = "New Cash Down";
      } else {
        this.label = "New Selling Price";
        var newSalePrice =
          this.lease_selling_price - adjustedAmount + this.lease_10percent;
        this.desking.newcashdown = newSalePrice;
        this.desking.ammountadjusted = this.lease_selling_price - newSalePrice;
      }
    },
    CustomService(val, counter) {
      this.counter = counter;

      if (val == "custom") {
        this.aftermarket[counter].name = "";
        $("#customeService").modal("show");
      } else {
        this.aftermarket[counter].name = val;
      }
    },
    selectService() {
      this.aftermarket[this.counter].service = this.service;
      $("#customeService").modal("hide");
    },
    getPercentageValueFinance() {
      this.l_finance_tax = (this.tax_value * 100) / this.selling_price;
      //  console.warn( this.l_finance_tax)
      // this.MainCalculationFinance();
    },
    getPercentageValueLease() {
      var tax = (this.tax_value * 100) / this.lease_selling_price;
      this.l_finance_tax = tax;
      this.MainCalculationLease();
    },
    getPercentageValueCash() {
      var tax = (this.tax_value * 100) / this.cash_selling_price;
      this.l_finance_tax = tax;
      this.this.MainCalculationCash();
    },
    updatemarketplace(counter) {
      if (this.aftermarket[counter].cost == "") {
        this.$toast.open({
          position: "top-right",
          message: "Cost  field is empty",
          type: "info",
        });
        return false;
      }

      if (this.aftermarket[counter].customer_price == "") {
        this.$toast.open({
          position: "top-right",
          message: "Price  field is empty",
          type: "info",
        });
        return false;
      } else {
        if (this.aftermarket[counter].service) {
          this.aftermarket[counter].name = this.service;
        }

        axios
          .post(
            "save/aftermarket",
            {
              lead_id: this.$route.params.id,
              aftermarket: this.aftermarket[counter],
            },
            {
              headers: {
                Authorization: "Bearer " + this.$storage.get("auth").token,
              },
            }
          )
          .then((res) => {
            if (res.data.success) {
              this.aftermarket_placeholder = res.data.cost;
              if (this.desking.deal_type == "finance") {
                this.MainCalculationFinance();
              }
              if (this.desking.deal_type == "cash") {
                this.MainCalculationCash();
              }
              if (this.desking.deal_type == "lease") {
                this.MainCalculationLease();
              }
              console.log(res.data.cost);

              this.service = "";
              this.aftermarket = [];
              this.getaftermarket();

              this.$toast.open({
                position: "top-right",
                message: res.data.message,
              });
            }
          });
      }
    },
    onChangeLender() {
      axios
        .get("get/lender/term/" + this.desking.l_lease_vsc_lease_company, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.lender = res.data.term;
            this.desking.l_lease_Seqrity_DEP = res.data.term.security_deposit;
            console.log(this.lender);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getLender() {
      axios
        .get("get/lender", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.lenderdata = res.data.lender;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    MainCalculationFinance() {
      var tax = (this.l_finance_tax / 100) * this.selling_price;
      console.log("tax" + tax);
      this.finance_tax_value = tax;
      var finalAmount1 =
        Number(this.selling_price) +
        Number(tax) -
        Number(this.desking.l_finance_net_trade);

      var finalAmount2 =
        Number(finalAmount1) +
        Number(this.sum_fee) +
        Number(this.maintenance_placeholder) +
        Number(this.aftermarket_placeholder) +
        Number(this.vsc_placeholder) +
        Number(this.gap_placeholder);
      console.log(
        "maintenance placeholder" + Number(this.maintenance_placeholder)
      );
      console.log(
        "after market placeholder" + Number(this.aftermarket_placeholder)
      );
      console.log("vc placeholder" + Number(this.vsc_placeholder));
      console.log("maintenance placeholder" + Number(this.gap_placeholder));
      var finalAmount =
        Number(finalAmount2) - Number(this.desking.l_finannce_rebate);
      console.log("finalammount1" + finalAmount1);
      console.log("finalammount2" + finalAmount2);
      console.log("net trade" + this.desking.l_finance_net_trade);
      console.log("final_abbount" + finalAmount);
      console.log("addon");
      console.log("addon" + this.calculateaddon);
      this.selling_price_placeholder = finalAmount;
      // This will run 3 times for CD1, CD2 and CD3
      var apr = this.desking.l_finance_apr / 12 / 100;
      console.log("fiance tax" + this.l_finance_tax);
      console.log("selling price" + this.selling_price);
      console.log("finace rebate" + this.desking.l_finance_net_trade);
      var forPowerFormula = 1 + apr;
      forPowerFormula = Math.pow(forPowerFormula, this.financec_terms);

      var payment1 = finalAmount - this.finance_10percent.toFixed(2);
      console.log("finannce_10" + this.finance_10percent.toFixed(2));
      console.log("finannce_15" + this.finance_15percent.toFixed(2));
      console.log("finannce_20" + this.finance_20percent.toFixed(2));
      this.downpayment1 =
        (payment1 * (apr * forPowerFormula)) / (forPowerFormula - 1);

      var payment2 = finalAmount - this.finance_15percent.toFixed(2);
      this.downpayment2 =
        (payment2 * (apr * forPowerFormula)) / (forPowerFormula - 1);

      var payment3 = finalAmount - this.finance_20percent.toFixed(2);
      this.downpayment3 =
        (payment3 * (apr * forPowerFormula)) / (forPowerFormula - 1);
    },
    MainCalculationCash() {
      var tax = (this.l_finance_tax / 100) * this.cash_selling_price;
      this.cash_tax_value = tax;
      var finalAmount =
        this.cash_selling_price + tax - this.desking.l_cash_net_trade;

      finalAmount = finalAmount + this.sum_fee + this.calculateaddon;

      finalAmount = finalAmount - this.desking.l_cash_rebate;

      this.cash_selling_price_placeholder = finalAmount;
    },

    MainCalculationLease() {
      console.log(this.l_lease_residual);
      var finalAmount =
        this.lease_selling_price +
        this.sum_fee +
        this.calculateaddon -
        (this.desking.l_lease_net_Trade + this.desking.l_lease_rebate);
      this.leaseFinalAmmount = finalAmount;
      this.lease_selling_price_placeholder = finalAmount;

      var ResidualValue =
        (this.l_lease_residual / 100) * this.lease_selling_price;
      this.desking.placeholder = ResidualValue;
      var tax = this.l_finance_tax / 100;
      this.lease_tax_value = tax * this.lease_selling_price;
      // this.programMilesPlaceholder = ResidualValue;
      var moneyFactor = this.desking.l_lease_apr / 2400;
      this.desking.l_lease_moneyfactor = moneyFactor;
      var payment1 = finalAmount - this.lease_10percent.toFixed(2);
      var monthlyDepriciation = (payment1 - ResidualValue) / this.lease_terms;
      var monthlyInterest = (payment1 + ResidualValue) * moneyFactor;
      var monthlyTax = tax * (monthlyDepriciation + monthlyInterest);
      //this.tax_value = monthlyTax;
      this.lease_downpayment1 =
        monthlyDepriciation + monthlyInterest + monthlyTax;
      var payment2 = finalAmount - this.lease_15percent.toFixed(2);
      monthlyDepriciation = (payment2 - ResidualValue) / this.lease_terms;
      monthlyInterest = (payment2 + ResidualValue) * moneyFactor;
      monthlyTax = tax * (monthlyDepriciation + monthlyInterest);
      // this.tax_value = monthlyTax;
      this.lease_downpayment2 =
        monthlyDepriciation + monthlyInterest + monthlyTax;

      var payment3 = finalAmount - this.lease_20percent.toFixed(2);
      monthlyDepriciation = (payment3 - ResidualValue) / this.lease_terms;
      monthlyInterest = (payment3 + ResidualValue) * moneyFactor;
      monthlyTax = tax * (monthlyDepriciation + monthlyInterest);
      //this.tax_value = monthlyTax;
      this.lease_downpayment3 =
        monthlyDepriciation + monthlyInterest + monthlyTax;
    },
    CalculationOnePayLease() {
      console.log(this.l_lease_residual);
      var finalAmount =
        this.lease_selling_price +
        this.sum_fee +
        this.calculateaddon -
        (this.desking.l_lease_net_Trade + this.desking.l_lease_rebate);
      this.lease_selling_price_placeholder = finalAmount;

      var ResidualValue =
        (this.l_lease_residual / 100) * this.lease_selling_price;
      this.desking.placeholder = ResidualValue;
      var tax = this.l_finance_tax / 100;
      this.tax_value = tax * this.lease_selling_price;
      // this.programMilesPlaceholder = ResidualValue;
      var moneyFactor = this.desking.l_lease_apr / 2400;
      this.desking.l_lease_moneyfactor = moneyFactor;
      var payment1 = finalAmount - this.lease_10percent;
      var monthlyDepriciation = (payment1 - ResidualValue) / this.lease_terms;
      var monthlyTax = tax * monthlyDepriciation;
      //this.tax_value = monthlyTax;
      this.desking.onePayLeaseplaceholder =
        monthlyDepriciation + monthlyTax * this.lease_terms;
    },
    onChangeRebate() {
      // this.selling_price_placeholder = this.selling_price_placeholder - this.desking.l_finannce_rebate
      return this.desking.l_finannce_rebate;
    },
    onChangeLeaseRebate() {
      this.lease_selling_price_placeholder =
        this.lease_selling_price_placeholder - this.desking.l_lease_rebate;
    },
    onFocusFees(index) {
      this.fees[index].show = true;
    },
    onChangeFees() {
      if (this.checkfee.length > 0) {
        var sum = 0;
        for (var i = 0; i < this.checkfee.length; i++) {
          sum += Number($(".field_" + this.checkfee[i].id).val());
        }
        this.sum_fee = sum;
      } else {
        this.sum_fee = 0;
      }
      this.MainCalculationFinance();
      this.MainCalculationLease();
      this.MainCalculationCash();
    },
    onChangePayLease(event) {
      if (event.target.checked) {
        this.onepaylease = 1;
        this.CalculationOnePayLease();
      } else {
        this.onepaylease = 0;
        this.MainCalculationLease();
      }
    },
    addRollPayment() {
      if (this.desking.rollinto == "cashdown") {
        this.lease_10percent = this.desking.newcashdown;
      } else {
        this.lease_selling_price = this.desking.newcashdown;
      }
      this.MainCalculationLease();

      $("#rollpaymentmodal").modal("hide");
    },
    openRollpaymentModal() {
      $("#rollpaymentmodal").modal("show");
    },
    removeaftermarket(id) {
      axios
        .get("aftermarket/delete/" + id + "/" + this.$route.params.id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.aftermarket_placeholder = res.data.sum;
            if (this.desking.deal_type == "finance") {
              this.MainCalculationFinance();
            }
            if (this.desking.deal_type == "cash") {
              this.MainCalculationCash();
            }
            if (this.desking.deal_type == "lease") {
              this.MainCalculationLease();
            }
            this.getaftermarket();
            this.$toast.open({
              position: "top-right",
              message: "Aftermarket Deleted Successfully",
              type: "success",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onChangevsc(val) {
      this.vsc_placeholder = val;
      this.addon_placeholder =
        this.vsc_placeholder +
        this.maintenance_placeholder +
        this.gap_placeholder +
        this.maintenance_placeholder;
      this.MainCalculationFinance();
      this.MainCalculationCash();
      this.MainCalculationLease();
    },
    onChangeGap(val) {
      this.gap_placeholder = val;
      this.addon_placeholder =
        this.vsc_placeholder +
        this.gap_placeholder +
        this.maintenance_placeholder;
      this.MainCalculationFinance();
      this.MainCalculationCash();
      this.MainCalculationLease();
    },
    onChangegapcustomerprice(val) {
      this.gap_placeholder = val;
      this.MainCalculationFinance();
      this.MainCalculationCash();
      this.MainCalculationLease();
    },
    onChangevsccustomerprice(val) {
      this.vsc_placeholder = val;
      this.MainCalculationFinance();
      this.MainCalculationCash();
      this.MainCalculationLease();
    },
    onChangemaintenancecustomerprice(val) {
      this.maintenance_placeholder = val;
      this.MainCalculationFinance();
      this.MainCalculationLease();
      this.MainCalculationCash();
    },
    onChangemaintenance(val) {
      this.addon_placeholder =
        this.vsc_placeholder +
        this.gap_placeholder +
        this.maintenance_placeholder;
      this.maintenance_placeholder = val;
      this.MainCalculationFinance();
      this.MainCalculationLease();
      this.MainCalculationCash();
    },
    getaftermarket() {
      axios
        .get("get/aftermarket/" + this.$route.params.id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            if (res.data.aftermarket) {
              this.aftermarketdata = res.data.aftermarket;
              this.aftermarket_placeholder = res.data.aftermarhetsum;
              this.MainCalculationFinance();
              this.MainCalculationCash();
              this.MainCalculationLease();
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // calculatefee(event, val) {
    //   if (event.target.checked) {
    //     this.sum_fee = this.sum_fee + val;
    //   } else {
    //     this.sum_fee = this.sum_fee - val;
    //   }
    // },
    addmarketplace() {
      this.aftermarket.push({
        name: "Change Tires",
        cost: "",
        customer_price: "",
        service: "",
      });
    },
    deletemarketplace(counter) {
      this.aftermarket.splice(counter, 1);
    },
    getFees() {
      axios
        .get("get/fees/" + this.$route.params.id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            if (res.data.fees) {
              this.fees = res.data.fees;

              this.$nextTick(() => {
                axios
                  .get("get/feesbylead/" + this.$route.params.id, {
                    headers: {
                      Authorization:
                        "Bearer " + this.$storage.get("auth").token,
                    },
                  })
                  .then((res) => {
                    if (res.data.success) {
                      if (res.data.fee) {
                        this.checkfee = res.data.fee;
                        this.onChangeFees();
                      }
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getVsc() {
      axios
        .get("get/vsc/" + this.$route.params.id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            if (res.data.vsc) {
              this.vsc = res.data.vsc;
              this.$nextTick(() => {
                axios
                  .get("get/vscbylead/" + this.$route.params.id, {
                    headers: {
                      Authorization:
                        "Bearer " + this.$storage.get("auth").token,
                    },
                  })
                  .then((res) => {
                    if (res.data.success) {
                      if (res.data.vsc) {
                        this.vsc_value = res.data.vsc;
                        this.onChangevsc(this.vsc_value);
                      }
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getGap() {
      axios
        .get("get/gap/" + this.$route.params.id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            if (res.data.gap) {
              this.gap = res.data.gap;
              this.$nextTick(() => {
                axios
                  .get("get/gapbylead/" + this.$route.params.id, {
                    headers: {
                      Authorization:
                        "Bearer " + this.$storage.get("auth").token,
                    },
                  })
                  .then((res) => {
                    if (res.data.success) {
                      if (res.data.gap) {
                        this.gap_value = res.data.gap;
                      }
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getMaintenance() {
      axios
        .get("get/maintenance/" + this.$route.params.id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            if (res.data.maintenance) {
              this.maintenance = res.data.maintenance;
              this.$nextTick(() => {
                axios
                  .get("get/maintenancebylead/" + this.$route.params.id, {
                    headers: {
                      Authorization:
                        "Bearer " + this.$storage.get("auth").token,
                    },
                  })
                  .then((res) => {
                    if (res.data.success) {
                      if (res.data.maintenance_val) {
                        this.maintenance_val = res.data.maintenance_val;
                      }
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onChangefinancetax() {
      // this.tax_value = (this.l_finance_tax / 100) * this.voi.v_price;
      return this.l_finance_tax;
    },
    onChnageTaxRate(event) {
      if (event.target.checked == false) {
        this.salestax = false;
        this.tax_value = 0.0;
      } else {
        this.salestax = true;
        this.tax_value =
          (this.l_finance_tax / 100) *
          (this.selling_price - this.desking.l_finance_net_trade);
      }
    },
    onChangecashnetTrade() {
      if (this.desking.l_cash_net_trade) {
        this.cash_selling_price_placeholder =
          this.cash_selling_price_placeholder - this.desking.l_cash_net_trade;
      } else {
        this.cash_selling_price_placeholder = this.voi.v_price;
      }
    },
    onChangefinance10percent() {
      this.downpayment1 =
        (this.selling_price - this.finance_10percent) / this.financec_terms;
    },
    onChangefinance15percent() {
      this.downpayment2 =
        (this.selling_price - this.finance_15percent) / this.financec_terms;
    },
    onChangefinance20percent() {
      this.downpayment3 =
        (this.selling_price - this.finance_20percent) / this.financec_terms;
    },
    onChangelease10percent() {
      this.lease_downpayment1 =
        (this.lease_selling_price - this.lease_10percent) / this.lease_terms;
    },
    onChangelease15percent() {
      this.lease_downpayment2 =
        (this.lease_selling_price - this.lease_15percent) / this.lease_terms;
    },
    onChangelease20percent() {
      this.lease_downpayment3 =
        (this.lease_selling_price - this.lease_20percent) / this.lease_terms;
    },
    onChangefinanceNetTrade() {
      // if (this.desking.l_finance_net_trade != "") {
      //   this.selling_price_placeholder =
      //     this.selling_price_placeholder - this.desking.l_finance_net_trade;
      //   this.downpayment1 =
      //     (this.selling_price_placeholder - this.finance_10percent) /
      //     this.financec_terms;
      //   this.downpayment2 =
      //     (this.selling_price_placeholder - this.finance_15percent) /
      //     this.financec_terms;
      //   this.downpayment3 =
      //     (this.selling_price_placeholder - this.finance_20percent) /
      //     this.financec_terms;
      // } else {
      //   this.selling_price_placeholder = this.voi.v_price;
      //   this.downpayment1 =
      //     (this.selling_price_placeholder - this.finance_10percent) /
      //     this.financec_terms;
      //   this.downpayment2 =
      //     (this.selling_price_placeholder - this.finance_15percent) /
      //     this.financec_terms;
      //   this.downpayment3 =
      //     (this.selling_price_placeholder - this.finance_20percent) /
      //     this.financec_terms;
      // }
      return this.desking.l_finance_net_trade;
    },

    onChangeleaseNetTrade() {
      if (this.desking.l_lease_net_Trade != "") {
        this.lease_selling_price_placeholder =
          this.lease_selling_price_placeholder - this.desking.l_lease_net_Trade;
        this.lease_downpayment1 =
          (this.lease_selling_price_placeholder - this.lease_10percent) /
          this.lease_terms;
        this.lease_downpayment2 =
          (this.lease_selling_price_placeholder - this.lease_15percent) /
          this.lease_terms;
        this.lease_downpayment3 =
          (this.lease_selling_price_placeholder - this.lease_20percent) /
          this.lease_terms;
      } else {
        this.lease_selling_price_placeholder = this.voi.v_price;
        this.lease_downpayment1 =
          (this.lease_selling_price_placeholder - this.lease_10percent) /
          this.lease_terms;
        this.lease_downpayment2 =
          (this.lease_selling_price_placeholder - this.lease_15percent) /
          this.lease_terms;
        this.lease_downpayment3 =
          (this.lease_selling_price_placeholder - this.lease_20percent) /
          this.lease_terms;
      }
    },
    onChangefinaceterms() {
      // if (this.financec_terms != "") {
      //   this.downpayment1 =
      //     (this.selling_price - this.finance_10percent) / this.financec_terms;
      //   this.downpayment2 =
      //     (this.selling_price - this.finance_15percent) / this.financec_terms;
      //   this.downpayment3 =
      //     (this.selling_price - this.finance_20percent) / this.financec_terms;
      // } else {
      //   this.financec_terms = 0.0;
      // }
      return this.financec_terms;
    },
    onChangecashterms() {
      if (this.cash_terms != "") {
        this.downpayment1 =
          (this.cash_price - this.finance_10percent) / this.cash_terms;
        this.downpayment2 =
          (this.cash_price - this.finance_15percent) / this.financec_terms;
        this.downpayment3 =
          (this.selling_price - this.finance_20percent) / this.financec_terms;
      } else {
        this.financec_terms = 0.0;
      }
    },

    onChangeleaseterms() {
      if (this.lease_terms != "") {
        this.lease_downpayment1 =
          (this.lease_selling_price_placeholder - this.lease_10percent) /
          this.lease_terms;
        this.lease_downpayment2 =
          (this.lease_selling_price_placeholder - this.lease_15percent) /
          this.lease_terms;
        this.lease_downpayment3 =
          (this.lease_selling_price_placeholder - this.lease_20percent) /
          this.lease_terms;
      } else {
        this.lease_terms = 0;
      }
    },
    previewReport() {
      var id = localStorage.getItem("id");
      console.log(id);
      console.log(id);
      axios
        .get("get/credit/preview/" + id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.html = res.data.iframe;
            $("#previewReport").modal("show");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteReport() {
      var id = localStorage.getItem("id");
      axios
        .get("report/delete/" + id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.getCredit();
            this.$toast.open({
              position: "top-right",
              message: "Report Deleted",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCredit() {
      axios
        .get("/get/credit/" + this.LDetail.c_id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.report = res.data.report;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    preview() {
      $("#previewModal").modal("show");
    },
    changeHandeler(val) {
      this.bureu = val;
    },
    getScore() {
      this.btnLoader = true;
      axios
        .post(
          "get/schore",
          {
            bureu: this.bureu,
            company: this.company,
            client: this.client,
            customer: this.LDetail.c_id,
            address: this.LDetail.c_street,
            zip: this.LDetail.c_zip,
            state: this.LDetail.c_state,
            fname: this.LDetail.c_first_name,
            lname: this.LDetail.c_last_name,
            city: this.LDetail.c_city,
            lead_id: this.$route.params.id,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((res) => {
          if (res.data.success) {
            if (res.data.message) {
              this.btnLoader = false;
              this.$toast.open({
                position: "top-right",
                message: res.data.message,
                type: "info",
              });
            } else {
              this.btnLoader = false;
              this.experian_score = "";
              this.transunion_score = "";
              this.equifax_score = "";
              this.getCredit();
              if (res.data.bureu == "Experian") {
                this.experian_score = res.data.score;
              }
              if (res.data.bureu == "Transunion") {
                this.transunion_score = 0;
              }
              if (res.data.bureu == "Equifax") {
                this.equifax_score = res.data.score;
              }
              if (res.data.score != "No score found") {
                this.previewReport();
              }
            }

            // this.html = res.data.iframe_html;
          }
        })
        .catch((err) => {
          this.btnLoader = false;
          console.log(err);
        });
    },
    adddesking() {
      if (this.desking.deal_type == "cash") {
        this.desking.total = this.cash_selling_price_placeholder;
      }
      if (this.desking.deal_type == "lease") {
        this.desking.total = this.lease_selling_price_placeholder;
      }
      if (this.desking.deal_type == "finance") {
        this.desking.total = this.selling_price_placeholder;
      }
      this.btnLoader = true;
      console.log(this.$route.params.id);
      this.desking.leadid = this.$route.params.id;
      this.desking.l_finance_d1 = this.finance_10percent;
      this.desking.l_finance_d2 = this.finance_15percent;
      this.desking.l_finance_d3 = this.finance_20percent;
      this.desking.l_lease_D1 = this.lease_10percent;
      this.desking.l_lease_D2 = this.lease_15percent;
      this.desking.l_lease_D3 = this.lease_20percent;
      this.desking.l_finance_financed_amount = this.finance_amount;
      this.desking.l_lease_financed_amount = this.lease_finance_amount;
      this.desking.l_finance_selling_price = this.selling_price;
      this.desking.salestax = this.l_finance_tax;
      this.desking.l_finance_terms = this.financec_terms;
      this.desking.l_lease_terms = this.lease_terms;
      this.desking.l_cash_selling_price = this.cash_selling_price;
      this.desking.l_lease_selling_price = this.lease_selling_price;
      this.desking.customer_id = this.LDetail.c_id;
      this.desking.vehicle = this.voi.v_new_used_status;
      this.desking.vehicle_data =
        this.voi.v_year + " " + this.voi.v_make + " " + this.voi.v_model;
      //fees
      this.desking.fees = this.checkfee;
      this.desking.vsc = this.vsc_value;
      this.desking.totalfee = this.sum_fee;
      this.desking.salestax = this.l_finance_tax;
      this.desking.maintenance = this.maintenance_val;
      this.desking.gap = this.gap_value;
      this.desking.vsc_placeholder = this.vsc_placeholder;
      this.desking.maintenance_placeholder = this.maintenance_placeholder;
      this.desking.gap_placeholder = this.gap_placeholder;
      this.desking.frontgross = this.frontgross;
      this.desking.backgross = this.backgross;
      axios
        .post("update/desking", this.desking, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.btnLoader = false;
            $("#leavemodal").modal("hide");
            this.$toast.open({
              position: "top-right",
              message: "Desking Updated",
            });
          }
        })
        .catch((err) => {
          this.btnLoader = false;
          console.log(err);
        });
    },
    getDesking() {
      axios
        .get("/get/desking/" + this.$route.params.id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            if (res.data.desking) {
              this.desking = res.data.desking;
              this.finance_10percent = res.data.desking.l_finance_d1;
              this.finance_15percent = res.data.desking.l_finance_d2;
              this.finance_20percent = res.data.desking.l_finance_d3;
              this.lease_10percent = res.data.desking.l_lease_D1;
              this.lease_15percent = res.data.desking.l_lease_D2;
              this.lease_20percent = res.data.desking.l_lease_D3;
              this.financec_terms = res.data.desking.l_finance_terms;
              this.lease_terms = res.data.desking.l_lease_terms;
              this.fromDataURL(res.data.desking.signature);

              if (res.data.desking.l_cash_selling_price) {
                this.cash_selling_price = res.data.desking.l_cash_selling_price;
              } else {
                this.cash_selling_price = this.voi.v_price;
              }
              if (res.data.desking.l_finance_selling_price) {
                this.selling_price = res.data.desking.l_finance_selling_price;
              } else {
                this.selling_price = this.voi.v_price;
              }
              if (res.data.desking.l_lease_selling_price) {
                this.lease_selling_price =
                  res.data.desking.l_lease_selling_price;
              } else {
                this.lease_selling_price = this.voi.v_price;
              }

              this.l_finance_tax = res.data.desking.salestax;
              setTimeout(function () {
                this.MainCalculationFinance();
                this.MainCalculationLease();
                this.MainCalculationLease();
              }, 2000);

              this.desking.l_finance_net_trade =
                res.data.desking.l_finance_net_trade;
              this.desking.l_lease_net_Trade =
                res.data.desking.l_lease_net_Trade;
              this.sum_fee = res.data.desking.totalfee;
              this.vsc_placeholder = res.data.desking.vsc_placeholder;
              this.maintenance_placeholder =
                res.data.desking.maintenance_placeholder;
              this.gap_placeholder = res.data.desking.gap_placeholder;
              this.aftermarket_placeholder =
                res.data.desking.aftermarket_placeholder;
              this.addon_placeholder =
                this.vsc_placeholder +
                this.aftermarket_placeholder +
                this.gap_placeholder +
                this.maintenance_placeholder;
              // if (res.data.desking.salestax) {
              //   this.tax_value =
              //     (res.data.desking.salestax / 100) * this.voi.v_price;
              // } else {
              //   this.tax_value = (8 / 100) * this.voi.v_price;
              // }

              if (this.desking.l_finance_net_trade) {
                this.selling_price_placeholder =
                  this.selling_price_placeholder -
                  this.desking.l_finance_net_trade;
              }
              if (this.desking.l_lease_net_Trade) {
                this.lease_selling_price_placeholder =
                  this.lease_selling_price_placeholder -
                  this.desking.l_lease_net_Trade;
              }
              if (this.desking.l_lease_rebate) {
                this.lease_selling_price_placeholder =
                  this.lease_selling_price_placeholder -
                  this.desking.l_lease_rebate;
              }
              if (this.desking.l_finannce_rebate) {
                this.selling_price_placeholder =
                  this.selling_price_placeholder -
                  this.desking.l_finannce_rebate;
              }

              if (res.data.desking.deal_type == "cash") {
                this.deal_type = true;
                this.finance = false;
                this.lease = false;
                $("#cash").prop("checked", true);
              }
              if (res.data.desking.deal_type == "finance") {
                this.deal_type = false;
                this.finance = true;
                this.lease = false;

                $("#finance").prop("checked", true);
              }
              if (res.data.desking.deal_type == "lease") {
                this.deal_type = false;
                this.finance = false;
                this.lease = true;
                $("#lease").prop("checked", true);
              }
            } else {
              this.gettax();
              setTimeout(function () {
                this.MainCalculationFinance();
                this.MainCalculationLease();
                this.MainCalculationLease();
              }, 2000);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    toggle(val) {
      if (val == "Cash") {
        this.deal_type = true;
        this.finance = false;
        this.lease = false;
        this.desking.deal_type = "cash";
      }
      if (val == "Finance") {
        this.deal_type = false;
        this.finance = true;
        this.lease = false;
        this.desking.deal_type = "finance";
      } else if (val == "Lease") {
        this.deal_type = false;
        this.finance = false;
        this.lease = true;
        this.desking.deal_type = "lease";
      }
    },
    getDealer() {
      axios
        .get("/get/dealer", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.data) {
            this.dealer = res.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteTrade(id) {
      axios
        .get("/delete/trade/" + id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            $("#trade_vehicleDetailModal").modal("hide");
            this.getTrades();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    trade_vehicleDetail(trade) {
      this.trade_ = {};
      this.trade_.years = trade.lti_vehicle_year;
      this.trade_.make = trade.lti_vehicle_make;
      this.trade_.vin = trade.lti_vehicle_vin;
      this.trade_.model = trade.lti_vehicle_model;
      this.trade_.style = trade.lti_vehicle_trim_style;
      this.trade_.color = trade.lti_vehicle_color;
      this.trade_.mileage = trade.lti_vehicle_mileage;
      this.trade_.acv = trade.lti_vehicle_acv;
      this.trade_.allowance = trade.lti_vehicle_allowance;
      this.trade_.notes = trade.lti_vehicle_notes;
      this.trade_.payoff = trade.lti_vehicle_payoff;
      this.trade_id = trade.lti_id;
      $("#trade_vehicleDetailModal").modal("show");
    },
    addTrade() {
      //console.log(this.trade_);
      this.trade_.lead_id = this.$route.params.id;
      axios
        .post("add_trade", this.trade_, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            $("#addTradeModal").modal("hide");
            this.trade_ = {};
            this.getTrades();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getTrades() {
      axios
        .get("tradeins/" + this.$route.params.id)
        .then((res) => {
          if (res.data.success) {
            const data = res.data;
            this.trades = data.success;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    vehicleDetail(v) {
      //console.log(v)
      this.v_detail = {};
      if (
        v.vid != undefined &&
        v.vid != "undefined" &&
        v.vid != null &&
        v.vid != ""
      ) {
        this.v_detail = {};
        this.v_detail.v_stock_no = v.stock_no;
        this.v_detail.v_mileage = v.mileage;
        this.v_detail.v_ext_color = v.ext_color;
        this.v_detail.v_int_color = v.int_color;
        this.v_detail.v_price = v.price;
        this.v_detail.v_engine = v.engine;
        this.v_detail.v_description = v.description;
        this.v_detail.v_image = v.img;
        this.v_detail.v_id = v.id;
        this.v_detail.v_vin = v.vin;
        this.v_detail.v_year = v.year;
        this.v_detail.v_make = v.make;
        this.v_detail.v_model = v.model;
        this.v_detail.v_vin = v.vin;
      } else {
        this.v_detail = v;
      }

      $("#vehicleDetailModal").modal("show");
    },
    getVOI() {
      axios
        .get("voi/" + this.$route.params.id)
        .then((res) => {
          if (res.data.success) {
            console.log(res.data);
            const data = res.data;
            this.voi = data.success;
            if (this.voi.v_msrp) {
              this.desking.l_finance_msrp = this.voi.v_msrp;
              this.desking.l_lease_msrp = this.voi.v_msrp;
            }
            if (this.voi) {
              this.desking.vehicle = this.voi.v_new_used_status;
              this.desking.vehicle_data =
                this.voi.v_year +
                " " +
                this.voi.v_make +
                " " +
                this.voi.v_model;
            }
            //get 10% of price
            if (this.voi.v_price) {
              this.finance_10percent = (10 / 100) * this.voi.v_price;
              console.log(this.finance_10percent);
              this.tax_value = (this.l_finance_tax / 100) * this.voi.v_price;
              //get 15 percent
              this.finance_15percent = (15 / 100) * this.voi.v_price;
              this.finance_20percent = (20 / 100) * this.voi.v_price;
              this.selling_price = this.voi.v_price;
              this.selling_price_placeholder = this.voi.v_price;
              this.lease_selling_price_placeholder = this.voi.v_price;
              this.desking.sellingprice = this.voi.v_price;
              this.lease_selling_price = this.voi.v_price;
              this.cash_selling_price = this.voi.v_price;
              this.cash_selling_price_placeholder = this.voi.v_price;
              this.downpayment1 =
                (this.selling_price - this.finance_10percent) /
                this.financec_terms;
              this.downpayment2 =
                (this.selling_price - this.finance_15percent) /
                this.financec_terms;
              this.downpayment3 =
                (this.selling_price - this.finance_20percent) /
                this.financec_terms;

              this.lease_10percent = (10 / 100) * this.voi.v_price;
              this.lease_15percent = (15 / 100) * this.voi.v_price;
              this.lease_20percent = (20 / 100) * this.voi.v_price;
              this.lease_downpayment1 =
                (this.lease_selling_price - this.lease_10percent) /
                this.lease_terms;
              this.lease_downpayment2 =
                (this.lease_selling_price - this.lease_15percent) /
                this.lease_terms;
              this.lease_downpayment3 =
                (this.lease_selling_price - this.lease_20percent) /
                this.lease_terms;
              this.MainCalculationFinance();
              this.MainCalculationLease();
              this.MainCalculationCash();
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateVOI(veh) {
      //console.log(veh)
      axios
        .post("update_voi/" + this.$route.params.id, veh, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.getVOI();
            $("#vehicleDetailModal").modal("hide");
            this.$root.$emit("action", "update_voi", res.data.vid);
            this.lead_detail.l_vehicle_id = res.data.vid;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    formattedAutocompleteDisplay(result) {
      return (
        result.year +
        " " +
        result.make +
        " " +
        result.model +
        " - " +
        result.vin
      );
    },
    selectedVehicle(result) {
      //console.log(result.selectedObject)
      this.v_detail = {};
      var v = result.selectedObject;
      this.v_detail.v_stock_no = v.stock_no;
      this.v_detail.v_mileage = v.mileage;
      this.v_detail.v_ext_color = v.ext_color;
      this.v_detail.v_int_color = v.int_color;
      this.v_detail.v_price = v.price;
      this.v_detail.v_engine = v.engine;
      this.v_detail.v_description = v.description;
      this.v_detail.v_id = v.id;
      this.v_detail.v_vin = v.vin;
      this.v_detail.v_year = v.year;
      this.v_detail.v_make = v.make;
      this.v_detail.v_model = v.model;

      if (
        v.imagelist != "" &&
        v.imagelist != undefined &&
        v.imagelist != null &&
        v.imagelist != "undefined"
      ) {
        var imgs = v.imagelist.split(",");
        this.v_detail.v_image = imgs[0];
      }

      $("#vehicleDetailModal").modal("show");
    },
    getAllEmployees() {
      axios
        .get("all_employees", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          const data = res.data;
          if (data.success) {
            this.allEmployees = data.success;
            this.assignTo = data.user_id;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getLeadDetail() {
      axios
        .get("lead_detail/" + this.$route.params.id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.LDetail = res.data.success;
            this.getCredit();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addData(aa) {
      if (aa == 1) {
        this.t_.prices = 5000;
        this.t_.disc = 500;
        this.t_.acces = 100;
        this.t_.fee = 500;
        this.t_.total = 5100;

        // this.t_ = {
        //     prices:2000,
        //     disc:500,
        //     acces: 100,
        //     fee: 500
        // }
      } else {
        this.t_.trade.prices = 300;
        this.t_.trade.lien = 100;
        this.t_.total = 4700;
      }
    },
  },
};
</script>
<style scoped>
.preview {
  cursor: pointer;
  margin-right: 10px;
}

.preview-btn {
  cursor: pointer;
}

.car {
  width: 356px;
}

table.dataTable td.dataTables_empty {
  display: none;
}

.actions-btns ul li a {
  cursor: pointer;
}

.finece-option .form-control {
  display: block;
  width: 100%;
  height: auto;
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #6d778e;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d5d8df;
  border-radius: 0.25rem;
}

.cash {
  font-size: 12px;
  width: 100px;
}

label {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 1.5;
  color: #6d778e;
}

.deleteafetrmarket {
  cursor: pointer;
}

.addmarketplace {
  cursor: pointer;
}

.desk-radio .containerw {
  width: auto;
  display: inline-block;
  position: relative;
  padding-left: 26px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  float: left;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-right: 15px;
  margin-top: 15px;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
}

.form-group .row {
  clear: both;
}

.price-placeholder {
  display: inline-block;
  border: 1px solid #d5d8df;
  border-radius: 3px;
  font-weight: bold;
  font-size: 16px;
  line-height: 1.5;
  padding: 3px 10px;
  color: #3151a1;
  float: right;
  position: relative;
  bottom: 25px;
  min-width: 100px;
  right: 2px;
  text-align: center;
}

.price-placeholder-addon {
  display: inline-block;
  border: 1px solid #d5d8df;
  border-radius: 3px;
  font-weight: bold;
  font-size: 16px;
  line-height: 1.5;
  padding: 3px 10px;
  color: #3151a1;
  float: right;
  position: relative;
  bottom: 24px;
  right: 2px;
  text-align: center;
  width: 97px;
}

.desking-box .detais-title h3 {
  font-size: 16px;
  color: #3151a1;
}

table {
  width: 100%;
}

select {
  display: block;
  width: 100%;
  height: auto;
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #343945;
  background-clip: padding-box;
  border: none;
}

h3 {
  font-weight: bold;
}
label {
  color: #6d778e;
}
.add-member .form-control {
  display: block;
  width: 100%;
  height: auto;
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #6d778e;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d5d8df;
  border-radius: 0.25rem;
}
.tax {
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #6d778e;
}
.switch {
  float: right;
}
.rollpayment {
  color: #3151a1;
  align-items: center;
  text-decoration-line: underline;
  font-size: 14px;
  cursor: pointer;
}
.payment {
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #6d778e;
  margin-top: 12px;
  line-height: 18px;
}
.price {
  color: #6d778e;
  font-size: 14px;
  line-height: 18px;
}
.dealneedsammount {
  color: #343945;
  font-size: 14px;
  float: right;
}
.option-table.setting-oppp {
  padding: 1px 0;
}
.divider {
  color: #ededed;
}
.whatever {
  background-color: red;
  display: inline-block;
  width: 100px;
  height: 100px;
}

#checkboxes input[type="checkbox"] {
  display: none;
}

#checkboxes input[type="checkbox"]:checked + .whatever {
  background-color: green;
}
.finance-box-area .finece-box p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5;
  color: #fff;
  margin-bottom: 5px;
}

.finance-box-area .finece-box h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 1.5;
  color: #fff;
}
.lease-timeline p {
  font-style: normal;
  line-height: 1.5;
  color: #163177 !important;
}
.lease-timeline h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 1.5;
  color: #3151a1 !important;
}
.addmore {
  display: inline-block;
  background: #dbe5ff;
  border-radius: 30px;
  padding: 8px 15px;
  color: #3151a1;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.3;
  text-decoration: none;
  margin: 5px;
}
.add-cus {
  cursor: pointer;
}
.apply {
  background: #dbe5ff;
  border-radius: 3px;
  color: #3151a1 !important;
  font-size: 14px;
  font-weight: 600;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 59px;
}
.td {
  width: 80px;
}
.tradein {
  position: relative;
  top: 20px;
}
.column {
  position: relative;
  right: 12px;
}
.paymentcash {
  padding-right: 0px !important;
  width: 75px !important;
}
@import "../../assets/css/dataTables.css";
</style>

 